import React, { useEffect, useState } from "react";
import {
  Table,
  Input,
  Space,
  Button,
  Typography,
  Select,
  Tabs,
  Spin,
  Tooltip,
  message
} from "antd";
import { CheckCircleTwoTone, CloseCircleTwoTone, EditTwoTone, SyncOutlined } from "@ant-design/icons";
import APIService from "../../services/api";
import { userGroups , typeObj } from "../../constants/common";
import AddEditUSer from "./AddEditUser";
import filter from 'lodash/filter';
const { Title } = Typography;
const { Search } = Input;
const { TabPane } = Tabs;
const { Option } = Select;
function UserManagement() {
  const scrollHeight = window.innerHeight - 337;
  const [modalData, setModalData] = useState({
    visible: false,
    data: {},
    success: false,
  });
  const [activeTab, updateTabData] = useState("allUsers");
  const [filterOrganization, updateOrganization] = useState();
  const [userType, setUserType] = useState("active")
  const [organizationList, setOrganizationList] = useState([]);
  const [fetching, setFetching] = React.useState(false);
  const [tableData, updateTableData] = useState({
    loading: false,
    dataSource: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
  });
  const [searchQuery, updateSearchQuery] = useState(undefined);
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "emails",
      ellipsis: true,
      render: (text) => {
        return text && text.length ? <Space><span>{text[0].address}</span> {text[0].verified ? (
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        ) : (
          <CloseCircleTwoTone twoToneColor="#FF0000" />
        )}</Space> : "";
      },
    },
    {
      title: "Phone No.",
      width: 130,
      dataIndex: "profile",
      render: (text) => {
        return text?.phoneNumber ? text?.phoneNumber : text?.phoneNumbers ? text?.phoneNumbers[0] : '';
      },
    },
    {
      title: "Organizations",
      dataIndex: "roles",
      align: "center",
      width: 130,
      render: (roles) => {
        return roles ?  activeTab !== "allUsers" ?  roles?.[activeTab] ? roles[activeTab].length : 0 :  roles[Object.keys(roles)[0]].length || 0 : 0
      },
    },
    {
      title: "Active",
      align: "center",
      width: 100,
      render: (obj) => {
        let active = obj.active;
        let userId = obj._id;
        return obj.active ? (
          <CheckCircleTwoTone onClick={() => updateActive(active, userId)} twoToneColor="#52c41a" />
        ) : (
          <CloseCircleTwoTone onClick={() => updateActive(active, userId)} twoToneColor="#FF0000" />
        );
      },
    },
    {
      title: "Actions",
      width: 100,
      align: 'center',
      render: (obj) => {
        let displaySync = false;
        if (obj.chatwoot?.userId) {
          let orgCount = obj.roles?.[activeTab] ? obj.roles[activeTab].length : 0;
          let accountCount = filter(obj.chatwoot.accountUsers, function (o) { return o.accountId; });
          if (orgCount !== accountCount.length) {
            displaySync = true;
          }
        }

        return (
          <Space>
            <EditTwoTone
              onClick={() =>
                setModalData({
                  ...modalData,
                  visible: true,
                  data: obj,
                })
              }
            />
            {displaySync && <Tooltip title="Chatwoot sync" color="#1cc93b"><SyncOutlined
              onClick={() =>
                syncUserAccounts(obj)
              }
            /></Tooltip>}

          </Space>
        );
      },
    },
  ];
  useEffect(() => {
    getOrganizationsData();
  }, []);
  useEffect(() => {
    getData(tableData.pagination);
  }, [activeTab, filterOrganization, searchQuery, userType]);

  useEffect(() => {
    if (modalData.success) {
      getData(tableData.pagination);
      setModalData({
        visible: false,
        data: {},
        success: false
      })
    }
  }, [modalData.success]);

  const debounceFetcher = (searchStr) => {
    setFetching(true);
    let searchQuery = "";
    if (searchStr) {
      searchQuery = {
        name: { $regex: searchStr, $options: "i" }
      };
    }
    getOrganizationsData(searchQuery);
  };

  const getOrganizationsData = (searchQuery) => {
    APIService.requestWithToken("api/v1/admin/org/list", "get", {
      query: searchQuery ? JSON.stringify(searchQuery) : null,
      select: JSON.stringify(["_id", "name"]),
      sort: JSON.stringify({ name: 1, createdAt: -1 }),
    }).then(({ res }) => {
      if (res && res.code === "OK" && res.data.count) {
        setFetching(false);
        let orgList = res.data.list.map((a) => {
          return {
            label: a.name,
            value: a._id,
          };
        });
        setOrganizationList(orgList);
      }
    });
  };

  const getData = (pagination) => {
    updateTableData({
      ...tableData,
      pagination: {
        ...pagination,
      },
      loading: true,
      dataSource: [],
    });
    let query = {};
    if (searchQuery) {
      query = { ...query, ...searchQuery };
    }
    if(activeTab !== "allUsers"){
      let rolesQuery = { [`roles.${activeTab}`]: { $exists: true } };
      query = { ...query, ...rolesQuery };
      if (filterOrganization) {
        query = { ...query, [`roles.${activeTab}`]: filterOrganization };
      }
      if(userType === "active"){
        query = {...query, active:true}
      }
      else if (userType === "deactivate"){
        query = {...query, ...query["$and"]={$or:[{active:false},{active:{$exists:false}}]}}
      }
    }
    APIService.requestWithToken("api/v1/admin/users", "get", {
      pageNo: parseInt(pagination.current),
      limit: pagination.pageSize,
      query: query ? JSON.stringify(query) : null,
      sort: JSON.stringify({ createdAt: -1 }),
    }).then(({ res }) => {
      if (res && res.code === "OK") {
        updateTableData({
          loading: false,
          dataSource: res.data.list,
          pagination: {
            ...pagination,
            total: res.data.count,
          },
        });
      }
    });
  };

  const updateActive = (value, userId) => {
    let reqObj = { deactivated: value, active: !value }

    APIService.requestWithToken("api/v1/admin/partial-update/" + userId, "post", reqObj).then(({ res }) => {
      if (res && res.code === "OK") {
        getData(tableData.pagination);
      }
    });

  }

  const syncUserAccounts = (data) => {
    updateTableData({ ...tableData, loading: true });
    let orgIds = data.roles[activeTab];
    APIService.chatwootAPI("api/v1/chatwoot/create-account-users", "post", { orgIds, userId: data._id, chatwoot: data.chatwoot }, { userid: data._id }).then(({ res }) => {
      if (res && res.code === "OK") {
        message.success('Chatwoot synced successfully.');
        getData(tableData.pagination);
      } else {
        message.error('Chatwoot sync failed.');
        updateTableData({ ...tableData, loading: false });

      }
    });


  }

  return (
    <div>
      <div className="df jsb am">
        <Title level={3}> User Management</Title>
        <Space>
          <Space>
            <Search
              placeholder="Search by email, phone number, firstname or last name"
              onSearch={(searchStr) => {
                updateSearchQuery({
                  $and:[
                    {
                    $or: [
                    { name: { $regex: searchStr, $options: "i" } },
                    { "emails.address": { $regex: searchStr, $options: "i" } },
                    { "profile.lastName": { $regex: searchStr, $options: "i" } },
                    { "profile.firstName": { $regex: searchStr, $options: "i" } },
                    { "profile.phoneNumber": { $regex: searchStr, $options: "i" } }
                    ]
                    }
                  ]
                  
                });
              }}
              style={{ width: 200 }}
            />

              {activeTab !== "allUsers" && (
                <Select
                  defaultValue={userType}
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Select an userType"
                  optionFilterProp="children"
                  onChange={(e)=> {setUserType(e)}}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {Object.entries(typeObj).map((group) => (
                    <Option key={group[0]} value={group[1]}>{`${group[0]
                      .replace(/([A-Z])/g, " $1")
                      .trim()
                      .toUpperCase()}`}</Option>
                  ))}
                </Select>
              )}
              
            <Select
              showSearch
              allowClear
              style={{ width: 200 }}
              placeholder="Select an Organization"
              optionFilterProp="children"
              onChange={(newVal) => updateOrganization(newVal)}
              onSearch={(value) => debounceFetcher(value)}
              filterOption={false}
              notFoundContent={fetching ? <Spin size="small" /> : null}
              value={filterOrganization}
              options={organizationList}
            ></Select>
          </Space>
          <Button
            type="primary"
            onClick={() =>
              setModalData({ ...modalData, visible: true })
            }
          >
            {" "}
            Add
          </Button>
        </Space>
      </div>
      <Tabs
        defaultActiveKey={activeTab}
        tabPosition="top"
        onChange={(value) => updateTabData(value)}
      >
        {Object.entries(userGroups).map((group) => (
          // console.log('group',group)
          <TabPane
            tab={`${group[0]
              .replace(/([A-Z])/g, " $1")
              .trim()
              .toUpperCase()}`}
            key={group[1]}
          >
            <Table
              {...tableData}
              columns={columns}
              onChange={getData}
              scroll={{ y: scrollHeight }}
            ></Table>
          </TabPane>
        ))}
      </Tabs>
      
      {modalData.visible ? (
        <AddEditUSer
          modalData={modalData}
          setModalData={setModalData}
        ></AddEditUSer>
      ) : null}
    </div>
  );

}

export default UserManagement;

import { Button, Col, Form, Input, notification, Row } from "antd";
import React, { useRef, useState } from "react";
import StorageService from "../../services/storage";

import APIService from "../../services/api";
const AthenaConfig = ({ id, data }) => {
  const [form] = Form.useForm();
  const openNotification = () => {
    notification.open({
      message: "Updated Successfully",
    });
  };
  const onFinish = () => {
    form
      .validateFields()
      .then(async (values) => {
        let userData = StorageService.getData("user");
        let requestObj = {
          ...values,
          userId: userData._id,
          organizationId: id,
          organizationName: data?.name,
          organizationType: data?.organizationType,
          athenaConfig: {
            practiceId: values?.practiceId || "",
            departmentId: values?.departmentId || "",
          },
        };
        console.log('requestObj',requestObj)
        const response = await APIService.requestWithToken(
          "api/v1/organization/addOrEditOrganization",
          "post",
          requestObj
        );

        if (
          response?.res?.message.includes("organization update successfully.")
        ) {
          openNotification();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Form
      form={form}
      initialValues={data}
      name="control-hooks"
      layout="vertical"
      onFinish={onFinish}
    >
      <Row justify="space-between">
        <Col xs={7} md={11}>
          <Form.Item name="practiceId" label="Practice Id">
            <Input placeholder="Practice Id" />
          </Form.Item>
        </Col>

        <Col xs={7} md={11}>
          <Form.Item name="departmentId" label="Department Id">
            <Input placeholder="Department Id" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        wrapperCol={{
          offset: 11,
          span: 16,
        }}
      >
        <div>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
export default AthenaConfig;

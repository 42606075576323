const StorageService = {
  getData: (key) => {
    let val = localStorage.getItem(key);
    try {
      return JSON.parse(val);
    } catch (error) {
      return val;
    }
  },
  setData: (key, val) => {
    if (val) {
      localStorage.setItem(
        key,
        typeof val === "object" ? JSON.stringify(val) : val.toString()
      );
      return true;
    } else {
      return false;
    }
  },
  clearAllData: () => {
    localStorage.clear();
    return true;
  },
};
export default StorageService;

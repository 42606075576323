import axios from "../utils/Api";
//import CommonService from "./common";
import StorageService from "./storage";
import { message } from "antd";
import variables from "../config";

const {
  BASE_URL,
  APPV2_URL,
  GOOGLE_MY_BUSINESS,
  GOOGLE_MY_BUSINESS_ACCOUNTID,
  CLIENT_ID,
  REFRESH_TOKEN,
  REDIRECT_URL,
  GRANT_TYPE,
  PROVIDER_SYNC_URL,
} = variables;

function encodeQueryString(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}
const APIService = {
  request(url, method, params) {
    let apiUrl = `${BASE_URL}/${url}`;
    let reqOptions = {
      method: method,
      url: apiUrl,
      data: params,
      headers: {},
    };
    let userToken = StorageService.getData("token");
    if (userToken) {
      reqOptions.headers.authorization = `Bearer ${userToken}`;
    }
    return axios(reqOptions)
      .then((res) => {
        return { res: res.data, headers: res.headers };
      })
      .catch(function (error) {
        if (error.response && error.response.data) {
          message.error(`${error.response.data.message}`);
        }
        if (error.message) {
          message.error(`${error.message}`);
        }
        return { res: [] };
      });
  },
  requestWithToken(url, method, params, headers) {
    let apiUrl = `${BASE_URL}/${url}`;
    if (method.toLowerCase() === "get" && params) {
      apiUrl += `?${encodeQueryString(params)}`;
    }
    let reqOptions = {
      method: method,
      url: apiUrl,
      data: params,
      headers: headers ? headers : {},
    };
    reqOptions.headers["access-token"] = `NPIZGPAQvzKoi1dLiuSQ2thRV4GSKfu5`;
    return axios(reqOptions)
      .then((res) => {
        return { res: res.data || [], headers: res.headers || {} };
      })
      .catch(function (error) {
        if (error.response && error.response.data) {
          message.error(error.response.data.message);
          return {};
        }
      });
  },
  chatwootAPI(url, method, params, headers) {
    let apiUrl = `${BASE_URL}/${url}`;
    let reqOptions = {
      method: method,
      url: apiUrl,
      data: params,
      headers: {
        userid: StorageService.getData("userId"),
      },
    };
    reqOptions.headers["access-token"] = `NPIZGPAQvzKoi1dLiuSQ2thRV4GSKfu5`;
    if (headers) {
      reqOptions.headers = { ...reqOptions.headers, ...headers };
    }
    return axios(reqOptions)
      .then((res) => {
        return { res: res.data || [], headers: res.headers || {} };
      })
      .catch(function (error) {
        if (error.response && error.response.data) {
          message.error(error.response.data.message);
          return {};
        }
      });
  },
  googleMyBusinessToken() {
    let payload = JSON.stringify({
      client_id: CLIENT_ID,
      client_secret: APPV2_URL,
      refresh_token: REFRESH_TOKEN,
      redirect_uri: REDIRECT_URL,
      grant_type: GRANT_TYPE,
    });
    let reqOptions = {
      method: "post",
      url: `${APPV2_URL}token`,
      data: payload,
      headers: {},
    };
    return axios(reqOptions)
      .then((res) => {
        return { res: res.data, headers: res.headers };
      })
      .catch(function (error) {
        if (error.response && error.response.data) {
          message.error(`${error.response.data.message}`);
        }
        if (error.message) {
          message.error(`${error.message}`);
        }
        return { res: [] };
      });
  },
  googleMyBusinessLocations(token) {
    let reqOptions = {
      method: "get",
      url: `${GOOGLE_MY_BUSINESS}/accounts/${GOOGLE_MY_BUSINESS_ACCOUNTID}/locations?readMask=name,title&pageSize=100&pageToken=`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    return axios(reqOptions)
      .then((res) => {
        return { data: res.data.locations };
      })
      .catch(function (error) {
        if (error.response && error.response.data) {
          message.error(`${error.response.data.message}`);
        }
        if (error.message) {
          message.error(`${error.message}`);
        }
        return { res: [] };
      });
  },
  providerSyncRequest(url, method, params) {
    let apiUrl = `${PROVIDER_SYNC_URL}/${url}`;
    let reqOptions = {
      method: method,
      url: apiUrl,
      data: params,
      headers: { access_token: `NPIZGPAQvzKoi1dLiuSQ2thRV4GSKfu5` },
    };
    let userToken = StorageService.getData("token");
    if (userToken) {
      reqOptions.headers.authorization = `Bearer ${userToken}`;
    }

    return axios(reqOptions)
      .then((res) => {
        return { res: res.data, headers: res.headers };
      })
      .catch(function (error) {
        if (error.response && error.response.data) {
          message.error(`${error.response.data.message}`);
        }
        if (error.message) {
          message.error(`${error.message}`);
        }
        return { res: [] };
      });
  },
};
export default APIService;

import React, { useEffect, useState } from "react";
import {
  Table,
  Input,
  Space,
  Button,
  Typography,
  Select,
  Popover,
  Spin,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import APIService from "../../services/api";
import moment from "moment";
const { Title } = Typography;
const { Search } = Input;

function Notifications() {
  const scrollHeight = window.innerHeight - 280;

  const [filterOrganization, updateOrganization] = useState();
  const [organizationList, setOrganizationList] = useState([]);
  const [fetching, setFetching] = React.useState(false);
  const [tableData, updateTableData] = useState({
    dataSource: [],
    loading: false,
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
  });
  const [searchQuery, updateSearchQuery] = useState(undefined);
  const columns = [
    {
      title: "Name",
      dataIndex: "to",
    },
    {
      title: "Notification Type",
      dataIndex: "communicationChannel",
    },
    {
      title: "Communicated For",
      dataIndex: "communicatedFor",
    },
    {
      title: "Content",
      dataIndex: "text",
      align: "center",
      render: (text, record) => {
        if (record.communicationChannel === "SMS") {
          return (
            <Popover content={<div>{record.text}</div>}>
              <InfoCircleOutlined className="cursor-pointer" />
            </Popover>
          );
        } else if (record.communicationChannel === "EMAIL") {
          return (
            <Popover
              content={
                <div dangerouslySetInnerHTML={{ __html: record.text }}></div>
              }
            >
              <InfoCircleOutlined className="cursor-pointer" />
            </Popover>
          );
        } else if (record.communicationChannel === "VOICE") {
          return (
            <Popover
              content={
                <div>
                  <ul>
                    {record.voiceContentTrack.map((v, i) => {
                      return <li key={i}>{v}</li>;
                    })}
                  </ul>
                </div>
              }
            >
              <InfoCircleOutlined className="cursor-pointer" />
            </Popover>
          );
        }
      },
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      render: (text) => {
        return moment(text).format("MM-DD-YYYY, hh:mm a");
      },
    },
  ];

  useEffect(() => {
    getOrganizationsData();
  }, []);

  useEffect(() => {
    getData(tableData.pagination);
  }, [filterOrganization, searchQuery]);

  const debounceFetcher = (searchStr) => {
    let searchQuery = "";
    if (searchStr) {
      searchQuery = { name: { $regex: searchStr, $options: "i" } };
    }
    getOrganizationsData(searchQuery);
  };

  const getOrganizationsData = (searchQuery) => {
    APIService.requestWithToken("api/v1/admin/org/list", "get", {
      query: searchQuery ? JSON.stringify(searchQuery) : null,
      select: JSON.stringify(["_id", "name"]),
      sort: JSON.stringify({ name: 1, createdAt: -1 }),
    }).then(({ res }) => {
      if (res && res.code === "OK" && res.data.count) {
        setFetching(false);
        let orgList = res.data.list.map((a) => {
          return {
            label: a.name,
            value: a._id,
          };
        });
        setOrganizationList(orgList);
      }
    });
  };

  const getData = (pagination) => {
    updateTableData({
      ...tableData,
      pagination: {
        ...pagination,
      },
      loading: true,
      dataSource: [],
    });
    let query = {};
    if (searchQuery) {
      query = { ...searchQuery };
    }

    if (filterOrganization) {
      query = { ...query, organizationId: filterOrganization };
    }

    APIService.requestWithToken(
      "api/v1/admin/patient/notification-list",
      "get",
      {
        pageNo: parseInt(pagination.current),
        limit: pagination.pageSize,
        query: query ? JSON.stringify(query) : null,
        sort: JSON.stringify({ createdAt: -1 }),
      }
    ).then(({ res }) => {
      if (res && res.code === "OK") {
        updateTableData({
          dataSource: res.data.list,
          pagination: {
            ...pagination,
            total: res.data.count,
          },
        });
      }
    });
  };

  return (
    <div>
      <div className="df jsb am">
        <Title level={3}> Patient Notifications</Title>
        <Space>
          <Space>
            <Search
              placeholder="Search by phone number or email"
              onSearch={(searchStr) => {
                updateSearchQuery({
                  $or: [
                    { communicatedFor: { $regex: searchStr, $options: "i" } },
                    { to: { $regex: searchStr, $options: "i" } },
                    {
                      communicationChannel: {
                        $regex: searchStr,
                        $options: "i",
                      },
                    },
                  ],
                });
              }}
              style={{ width: 200 }}
            />
            <Select
              placeholder="Select Communication Channel"
              onChange={(e) =>
                updateSearchQuery({ ...searchQuery, communicationChannel: e })
              }
            >
              <Select.Option value="SMS">SMS</Select.Option>
              <Select.Option value="VOICE">VOICE</Select.Option>
              <Select.Option value="EMAIL">EMAIL</Select.Option>
            </Select>
            <Select
              showSearch
              allowClear
              style={{ width: 200 }}
              placeholder="Select an Organization"
              optionFilterProp="children"
              onChange={(newVal) => updateOrganization(newVal)}
              onSearch={(value) => debounceFetcher(value)}
              filterOption={false}
              notFoundContent={fetching ? <Spin size="small" /> : null}
              value={filterOrganization}
              options={organizationList}
            ></Select>
          </Space>
        </Space>
      </div>
      <Table
        {...tableData}
        columns={columns}
        onChange={getData}
        scroll={{ y: scrollHeight }}
      ></Table>
    </div>
  );
}

export default Notifications;

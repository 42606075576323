import React, { useRef, useState, useReducer } from 'react'
import { Button, Checkbox, Col, Form, Input, Row, Select, Tag } from 'antd'
import { TweenOneGroup } from 'rc-tween-one';
import { PlusOutlined } from '@ant-design/icons';

import { organizationType, timeZone } from "../../../constants/common";

const { Option } = Select;

const reducer = (state, action) => {
  switch (action.type) {
    case 'PMP_ONLY':
      return { ...state, isPMPOnly: action?.isPMPOnly };
    case 'TELEMEDICINE':
      return { ...state, isTeleMedicineServices: action?.isTeleMedicineServices };
    case 'HIDE_CHAT':
      return { ...state, isHideChat: action?.isHideChat };
    case 'NOTIFICATION_BELL':
      return { ...state, isNotificationBell: action?.isNotificationBell };
    case 'E_REGISTRATION':
      return { ...state, isERegistration: action?.isERegistration };
    case 'CARE_PLUS':
      return { ...state, isCarePlus: action?.isCarePlus };
    case 'PAYMENTS':
      return { ...state, isPayments: action?.isPayments };
    case 'Referrals':
      return { ...state, isReferrals: action?.isReferrals };
    case 'CAREERS':
      return { ...state, isCareers: action?.isCareers };
    case 'OUTREACH':
      return { ...state, isOutreach: action?.isOutreach };
    case 'INSURANCES':
      return { ...state, isInsurances: action?.isInsurances };
    case 'GROWTH_TOOLS':
      return { ...state, isGrowthTools: action?.isGrowthTools };
    default:
      return state;
  }
};

function General({ next, setStepData, stepData, setTags, tags, departmentsData }) {
  const [form] = Form.useForm();
  // const [tags, setTags] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);

  const initialStats = {
    isPMPOnly: stepData?.PMPOnly ? true : false,
    isTeleMedicineServices: stepData?.teleMedicineServices ? true : false,
    isHideChat: stepData?.steerAppHideChat ? true : false,
    isNotificationBell: stepData?.steerAppHideNotificationBell ? true : false,
    isERegistration: stepData?.steerAppHideERegistration ? true : false,
    isCarePlus: stepData?.steerAppHideCarePlus ? true : false,
    isPayments: stepData?.steerAppHidePayments ? true : false,
    isReferrals: stepData?.steerAppHideReferrals ? true : false,
    isCareers: stepData?.steerAppHideCareers ? true : false,
    isOutreach: stepData?.steerAppHideOutreach ? true : false,
    isInsurances: stepData?.hideInsurancePage ? true : false,
    isGrowthTools: stepData?.steerAppHideGrowthTools ? true : false,
  }
  const [state, dispatch] = useReducer(reducer, initialStats);

  // const [selectedItems, setSelectedItems] = useState([]);
  // const [departments, setDepartments] = useState([])

  // const filteredOptions = departmentsData.filter((department) => !selectedItems.includes(department.name));

  const onFinish = (values) => {
    setStepData({
      ...values,
      teleMedicineServices: state?.isTeleMedicineServices,
      PMPOnly: state?.isPMPOnly,
      steerAppHideChat: state?.isHideChat,
      steerAppHideNotificationBell: state?.isNotificationBell,
      steerAppHideERegistration: state?.isERegistration,
      steerAppHideCarePlus: state?.isCarePlus,
      steerAppHidePayments: state?.isPayments,
      steerAppHideReferrals: state?.isReferrals,
      steerAppHideCareers: state?.isCareers,
      steerAppHideOutreach: state?.isOutreach,
      hideInsurancePage: state?.isInsurances,
      steerAppHideGrowthTools: state?.isGrowthTools,
    });
    next();
  };

  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue]);
    }

    setInputVisible(false);
    setInputValue('');
  };

  const forMap = (tag) => {
    const tagElem = (
      <Tag
        closable
        onClose={(e) => {
          e.preventDefault();
          handleClose(tag);
        }}
      >

        {tag}
      </Tag>
    );
    return (
      <span
        key={tag}
        style={{
          display: 'inline-block',
        }}
      >
        {tagElem}
      </span>
    );
  };

  const tagChild = tags.map(forMap);

  return (
    <Form
      form={form}
      name="control-hooks"
      layout="vertical"
      onFinish={onFinish}
      initialValues={stepData}
    >
      <Form.Item
        name="organizationName"
        label="Organization Name"
        rules={[{ required: true }]}
      >
        <Input
          placeholder="Organization Name"
        />
      </Form.Item>
      <Form.Item
        name="abbreviationName"
        label="Abbreviation Name"
        rules={[{ required: true }]}
      >
        <Input
          placeholder="Abbreviation Name"
        />
      </Form.Item>

      <Row justify="space-between">
        <Col xs={7} md={11}>
          <Form.Item
            name="street"
            label="Street Address"
            rules={[{ required: true }]}
          >
            <Input
              placeholder="Street Address"
            />
          </Form.Item>
        </Col>

        <Col xs={7} md={11}>
          <Form.Item
            name="city"
            label="City"
            rules={[{ required: true }]}
          >
            <Input
              placeholder="City"
            /></Form.Item>
        </Col>
      </Row>

      <Row justify="space-between">
        <Col xs={7} md={11}>
          <Form.Item
            name="state"
            label="State"
            rules={[{ required: true }]}
          >
            <Input
              placeholder="State"
            />
          </Form.Item>
        </Col>

        <Col xs={7} md={11}>
          <Form.Item
            name="zipCode"
            label="Zip Code"
            rules={[{ required: true }]}
          >
            <Input
              placeholder="Zip Code"
            /></Form.Item>
        </Col>
      </Row>

      <Row justify="space-between">
        <Col xs={7} md={11}>
          <Form.Item
            name="phoneNumber"
            label="Phone Number"
            rules={[{ required: true }]}
          >
            <Input
              placeholder="Phone Number"
            />
          </Form.Item>
        </Col>

        <Col xs={7} md={11}>
          <Form.Item
            name="faxNumber"
            label="Fax"
            rules={[{ required: true }]}
          >
            <Input
              placeholder="Fax"
            /></Form.Item>
        </Col>
      </Row>
      <Col xs={7} md={11}>
        <Form.Item
          name="timeZone"
          label="TimeZone"
          rules={[{ required: true }]}
        // rules={[{ required: true, message: "Organization type is required." }]}
        >
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder="Select a TimeZone"
            optionFilterProp="children"
            // onChange={(e) => setZone(e)}
            filterOption={(input, option) =>
              option.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {Object.entries(timeZone).map((group) => (
              <Option key={group[1]} value={group[1]}>{`${group[1]
                // .replace(/([A-Z])/g, " $1")
                .trim()
                .toUpperCase()}`}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>

      <Row justify="space-between">
        <Col xs={7} md={11}>
          <Form.Item
            name="facilityUid"
            label="Facility Uid"
            rules={[{ required: true }]}
          // onChange={(e) => setFacilityUid(e.target.value)}
          >
            <Input
              placeholder="Facility Uid"
            />
          </Form.Item>
        </Col>

        <Col xs={7} md={11}>
          <Form.Item
            name="CarePlus incoming InBasket"
            label="CarePlus incoming InBasket"
            rules={[{ required: true }]}
          // onChange={(e) => setFacilityCode(e.target.value)}
          >
            <Input
              placeholder="CarePlus incoming InBasket"
            /></Form.Item>
        </Col>
      </Row>

      <Row justify="space-between">
        <Col xs={7} md={11}>
          <Form.Item
            name="departments"
            label="Departments"
          // onChange={(e) => setDepartments(e.target.value)}
          // rules={[{ required: true }]}
          >
            <>
              {!inputVisible && (
                <Tag onClick={showInput} className="site-tag-plus">
                  <PlusOutlined /> New Departments
                </Tag>
              )}
              {inputVisible && (
                <Input
                  ref={inputRef}
                  type="text"
                  size="medium"
                  placeholder="Departments"
                  maxLength={20}
                  // rules={[{ required: true, message: "Only Number allowed" }]}
                  style={{
                    width: "100%",
                  }}
                  value={inputValue}
                  onChange={handleInputChange}
                  onBlur={handleInputConfirm}
                  onPressEnter={handleInputConfirm}
                />
              )}
              <div style={{ marginTop: 16 }}>
                <TweenOneGroup
                  enter={{
                    scale: 0.8,
                    opacity: 0,
                    type: 'from',
                    duration: 100,
                  }}
                  onEnd={(e) => {
                    if (e.type === 'appear' || e.type === 'enter') {
                      e.target.style = 'display: inline-block';
                    }
                  }}
                  leave={{
                    opacity: 0,
                    width: 0,
                    scale: 0,
                    duration: 200,
                  }}
                  appear={false}
                >
                  {tagChild}
                </TweenOneGroup>
              </div>
            </>
            {/* <Select
              mode="tags"
              placeholder="Select Departments"
              value={selectedItems}
              // onChange={handleChange}
              // onChange={setSelectedItems}
              style={{ width: '100%' }}
              tokenSeparators={[',']}
            >
              {filteredOptions.map((item, key) => {
                return (
                  <Select.Option key={key?._id} value={item._id}>
                    {item?.name}
                  </Select.Option >
                )
              })}
            </Select> */}
          </Form.Item>
        </Col>

        <Col xs={7} md={11}>
          <Form.Item
            name="organizationType"
            label="Organization Type"
            rules={[{ required: true, message: "Organization type is required." }]}
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Select a Type"
              optionFilterProp="children"
              // onChange={(e) => setOrgType(e)}
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {Object.entries(organizationType).map((group) => (
                <Option key={group[0]} value={group[1]}>{`${group[0]
                  .replace(/([A-Z])/g, " $1")
                  .trim()
                  .toUpperCase()}`}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row style={{ marginBottom: "2rem", }}>
        <Col xs={12} md={6} >
          <Checkbox style={{ marginLeft: "8px" }} checked={state?.isPMPOnly} onChange={(e) => dispatch({ type: 'PMP_ONLY', isPMPOnly: !state?.isPMPOnly })}>PMPOnly</Checkbox>
        </Col>
        <Col xs={12} md={6} >
          <Checkbox
            checked={state?.isTeleMedicineServices}
            onChange={(e) => dispatch({ type: 'TELEMEDICINE', isTeleMedicineServices: !state?.isTeleMedicineServices })}
          >TeleMedicine Services
          </Checkbox>
        </Col>
        <Col xs={12} md={6}>
          <Checkbox checked={state?.isHideChat} onChange={(e) => dispatch({ type: 'HIDE_CHAT', isHideChat: !state?.isHideChat })}>
            Chat page
          </Checkbox>
        </Col>
        <Col xs={12} md={6}>
          <Checkbox
            checked={state?.isNotificationBell}
            onChange={(e) => dispatch({ type: 'NOTIFICATION_BELL', isNotificationBell: !state?.isNotificationBell })}
          >
            Notification Bell
          </Checkbox>
        </Col>
        <Col xs={12} md={6}>
          <Checkbox style={{ marginLeft: "8px" }} checked={state?.isERegistration} onChange={(e) => dispatch({ type: 'E_REGISTRATION', isERegistration: !state?.isERegistration })}>
            E Registration
          </Checkbox>
        </Col>
        <Col xs={12} md={6}>
          <Checkbox checked={state?.isCarePlus} onChange={(e) => dispatch({ type: 'CARE_PLUS', isCarePlus: !state?.isCarePlus })}>
            Care Plus
          </Checkbox>
        </Col>
        <Col xs={12} md={6}>
          <Checkbox checked={state?.isPayments} onChange={(e) => dispatch({ type: 'PAYMENTS', isPayments: !state?.isPayments })}>
            Payments
          </Checkbox>
        </Col>
        <Col xs={12} md={6}>
          <Checkbox checked={state?.isReferrals} onChange={(e) => dispatch({ type: 'Referrals', isReferrals: !state?.isReferrals })}>
            Referrals
          </Checkbox>
        </Col>
        <Col xs={12} md={6}>
          <Checkbox style={{ marginLeft: "8px" }} checked={state?.isCareers} onChange={(e) => dispatch({ type: 'CAREERS', isCareers: !state?.isCareers })}>
            Careers
          </Checkbox>
        </Col>
        <Col xs={12} md={6}>
          <Checkbox checked={state?.isOutreach} onChange={(e) => dispatch({ type: 'OUTREACH', isOutreach: !state?.isOutreach })}>
            Outreach
          </Checkbox>
        </Col>
        <Col xs={12} md={6}>
          <Checkbox checked={state?.isInsurances} onChange={(e) => dispatch({ type: 'INSURANCES', isInsurances: !state?.isInsurances })}>
            Insurances
          </Checkbox>
        </Col>
        <Col xs={12} md={6}>
          <Checkbox checked={state?.isGrowthTools} onChange={(e) => dispatch({ type: 'GROWTH_TOOLS', isGrowthTools: !state?.isGrowthTools })}>
            Growth Tools
          </Checkbox>
        </Col>
      </Row>
      <Form.Item
        wrapperCol={{
          offset: 11,
          span: 16,
        }}
      >
        <div>
          <Button type="primary" htmlType="submit">
            Next
          </Button>
        </div>
      </Form.Item>
    </Form>
  )
}

export default General

import React from "react";

import { Row, Divider, Col } from "antd";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";

import APIService from "../../services/api";
import CsvDownloader from "react-csv-downloader";

function UploadProviderTab({ data, id }) {
  const handleChange = (value) => {
    // console.log(value.target.files[0]);
    const formData = new FormData();
    formData.append("file", value.target.files[0]);
    if (formData) {
      setTimeout(() => {
        APIService.requestWithToken(
          `api/v1/organization/upload-providers/${id}`,
          "post",
          formData
        )
          .then((res) => {
            console.log({ res });
          })
          .catch((err) => console.log(err));
      }, 5000);
    }
  };

  const handleChangeEHP = (value) => {
    // console.log(value.target.files[0]);
    const formData = new FormData();
    formData.append("ehpOrgId", id);
    formData.append("file", value.target.files[0]);
    if (formData) {
      setTimeout(() => {
        APIService.providerSyncRequest(`api/v1/ehpUpload`, "post", formData)
          .then((res) => {
            console.log({ res });
          })
          .catch((err) => console.log(err));
      }, 5000);
    }
  };
  function handleSubmitMdstaff(event) {
    // console.log(value.target.files[0]);
    console.log(id);
    setTimeout(() => {
      APIService.providerSyncRequest(`api/v1/sync/${id}`, "get")
        .then((res) => {
          console.log({ res });
        })
        .catch((err) => console.log(err));
    }, 5000);
  }

  const columns = [
    {
      id: "first",
      displayName: "Membership_Status",
    },
    {
      id: "second",
      displayName: "Nearest_Facility",
    },
    {
      id: "third",
      displayName: "Prime_Privileges",
    },
    {
      id: "fourth",
      displayName: "Physician_Type",
    },
    {
      id: "fifth",
      displayName: "PCP_ID",
    },
    {
      id: "six",
      displayName: "Specialty",
    },
    {
      id: "seven",
      displayName: "Sub_Specialty",
    },
    {
      id: "eight",
      displayName: "Gender",
    },
    {
      id: "nine",
      displayName: "FirstName",
    },
    {
      id: "ten",
      displayName: "LastName",
    },
    {
      id: "eleven",
      displayName: "MiddleName",
    },
    {
      id: "12",
      displayName: "Title",
    },
    {
      id: "13",
      displayName: "Service_Location",
    },
    {
      id: "14",
      displayName: "Address",
    },
    {
      id: "15",
      displayName: "City",
    },
    {
      id: "16",
      displayName: "State",
    },
    {
      id: "14",
      displayName: "zip",
    },
    {
      id: "15",
      displayName: "Phone",
    },
    {
      id: "16",
      displayName: "Fax",
    },
    {
      id: "17",
      displayName: "Individual_NPI",
    },
    {
      id: "18",
      displayName: "Preferred_Provider",
    },
    {
      id: "19",
      displayName: "Service_Details",
    },
    {
      id: "20",
      displayName: "Employed",
    },
    {
      id: "21",
      displayName: "Provider_Name",
    },
    {
      id: "22",
      displayName: "Concatenate",
    },
    {
      id: "23",
      displayName: "Index",
    },
  ];

  const datas = [
    {
      first: "foo",
      second: "bar",
    },
    {
      first: "foobar",
      second: "foobar",
    },
  ];

  return (
    <Row>
      <Col xs={7} md={11}>
        <div>
          <div className="csv_title">
            <label>Upload CSV</label>
          </div>
          <div className="upload-btn-wrapper">
            <button className="btn">
              <UploadOutlined />
              <span className="btn-csv">Upload CSV file</span>
            </button>
            <input
              type="file"
              accept=".csv"
              onChange={handleChange}
              size="60"
            />
          </div>
        </div>
      </Col>
      <Divider type="vertical" />
      <Col xs={7} md={11}>
        <div className="download-div">
          <div className="csv_title">
            <label>Sample CSV</label>
          </div>
          <div>
            <CsvDownloader
              className="btn"
              filename="sample-provider"
              extension=".csv"
              // separator=";"
              // wrapColumnChar="'"
              columns={columns}
              datas={datas}
              text={
                <>
                  <DownloadOutlined />{" "}
                  <span className="btn-csv">Download CSV file</span>
                </>
              }
            />
          </div>
        </div>
      </Col>
      <Divider type="vertical" />
      <Col xs={7} md={11}>
        <div>
          <div className="csv_title">
            <label>Upload EHP File</label>
          </div>
          <div className="upload-btn-wrapper">
            <button className="btn">
              <UploadOutlined />
              <span className="btn-csv">Upload EHP File</span>
            </button>
            <input
              type="file"
              accept=".csv"
              onChange={handleChangeEHP}
              size="60"
            />
          </div>
        </div>
      </Col>
      <Divider type="vertical" />
      <Col xs={7} md={11}>
        <div className="download-div">
          <div className="csv_title">
            <label>Start MdStaff Provider Sync</label>
          </div>{" "}
          <div className="upload-btn-wrapper">
            <button className="btn" onClick={(e) => handleSubmitMdstaff(e)}>
              <span className="btn-csv">Start MdStaff Provider Sync</span>
            </button>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default UploadProviderTab;

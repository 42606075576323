import moment from "moment";
const CommonService = {
  serialize: (obj) => {
    let str =
      "?" +
      Object.keys(obj)
        .reduce(function (a, k) {
          a.push(k + "=" + encodeURIComponent(obj[k]));
          return a;
        }, [])
        .join("&");
    return str;
  },
  unCamelCase(str) {
    return (
      str
        // insert a space between lower & upper
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        // space before last upper in a sequence followed by lower
        .replace(/\b([A-Z]+)([A-Z])([a-z])/, "$1 $2$3")
        // uppercase the first character
        .replace(/^./, function (str) {
          return str.toUpperCase();
        })
    );
  },
  initial(str) {
    let result = "";
    if (str) {
      let splitArr = str.split(" ");
      splitArr.forEach((e) => {
        result += e.substr(0, 1);
      });
    }
    return result;
  },
  formatDate(date) {
    return date ? moment(date).format("MM/DD/YYYY hh:mm a") : "";
  },
};
export default CommonService;

import { Button, Col, Form, Input, notification, Row, Tag } from "antd"
import { TweenOneGroup } from "rc-tween-one"
import { PlusOutlined } from "@ant-design/icons"
import React, { useRef, useState } from "react"
import APIService from "../../../services/api"
import StorageService from "../../../services/storage"
import "../index.css"

const SteerConcierge = ({ data, id }) => {
  const [tagsForEmail, setTagsForEmail] = useState(
    data?.steerConcierge?.escalationRecipients?.email
      ? data?.steerConcierge?.escalationRecipients?.email
      : []
  )
  const [tagsForPhone, setTagsForPhone] = useState(
    data?.steerConcierge?.escalationRecipients?.phoneNumbers
      ? data?.steerConcierge?.escalationRecipients?.phoneNumbers
      : []
  )
  const [emailInputVisible, setEmailInputVisible] = useState(false)
  const [phoneInputVisible, setPhoneInputVisible] = useState(false)
  const [emailInputValue, setEmailInputValue] = useState("")
  const [phoneInputValue, setPhoneInputValue] = useState("")
  const inputRef = useRef(null)

  const [form] = Form.useForm()
  const openNotification = () => {
    notification.open({
      message: "Updated Successfully",
    })
  }

  const onFinish = (values) => {
    form
      .validateFields()
      .then(() => {
        let userData = StorageService.getData("user")
        let requestObj = {
          steerConcierge: {
            roomService: {
              phoneNumber: values.roomPhoneNumber,
              email: values.roomEmail,
            },
            foodService: {
              phoneNumber: values.foodPhoneNumber,
              email: values.foodEmail,
            },
            nursingStaff: {
              phoneNumber: values.nursingPhoneNumber,
              email: values.nursingEmail,
            },
            bathroomHelp: {
              phoneNumber: values.bathroomPhoneNumber,
              email: values.bathroomEmail,
            },
            other: {
              phoneNumber: values.otherPhoneNumber,
              email: values.otherEmail,
            },
            escalationRecipients: {
              emails: tagsForEmail,
              phoneNumbers: tagsForPhone,
            },
          },
          userId: userData?._id,
          organizationId: id,
          organizationName: data?.name,
          organizationType: data?.organizationType,
        }
        APIService.requestWithToken(
          "api/v1/organization/addOrEditOrganization",
          "post",
          requestObj
        )
      })
      .finally(() => {
        openNotification()
      })
  }

  const handleClose = (removedTag) => {
    if (tagsForEmail) {
      const newTags = tagsForEmail.filter((tag) => tag !== removedTag)
      setTagsForEmail(newTags)
    }
    if (tagsForPhone) {
      const newTags = tagsForPhone.filter((tag) => tag !== removedTag)
      setTagsForPhone(newTags)
    }
  }

  const showInput = (input) => {
    if (input === "phoneNumber") {
      setPhoneInputVisible(true)
    }
    if (input === "email") {
      setEmailInputVisible(true)
    }
  }

  const handleInputChange = (e, name) => {
    if (name === "email") {
      setEmailInputValue(e.target.value)
    }
    if (name === "phone") {
      setPhoneInputValue(e.target.value)
    }
  }

  const handleInputConfirm = () => {
    if (emailInputValue && tagsForEmail.indexOf(emailInputValue) === -1) {
      setTagsForEmail([...tagsForEmail, emailInputValue])
    }

    if (phoneInputValue && tagsForPhone.indexOf(phoneInputValue) === -1) {
      setTagsForPhone([...tagsForPhone, phoneInputValue])
    }

    setEmailInputVisible(false)
    setPhoneInputVisible(false)

    setEmailInputValue("")
    setPhoneInputValue("")
  }

  const forMap = (tag) => {
    const tagElem = (
      <Tag
        closable
        onClose={(e) => {
          e.preventDefault()
          handleClose(tag)
        }}
      >
        {tag}
      </Tag>
    )
    return (
      <span
        key={tag}
        style={{
          display: "inline-block",
        }}
      >
        {tagElem}
      </span>
    )
  }

  const EmailTagChild = tagsForEmail.map(forMap)
  const PhoneTagChild = tagsForPhone.map(forMap)

  if (data === {}) return false

  return (
    <Form
      // form={form}
      initialValues={{
        roomEmail: data?.steerConcierge?.roomService?.email,
        roomPhoneNumber: data?.steerConcierge?.roomService?.phoneNumber,
        foodEmail: data?.steerConcierge?.foodService?.email,
        foodPhoneNumber: data?.steerConcierge?.foodService?.phoneNumber,
        nursingEmail: data?.steerConcierge?.nursingStaff?.email,
        nursingPhoneNumber: data?.steerConcierge?.nursingStaff?.phoneNumber,
        bathroomEmail: data?.steerConcierge?.bathroomHelp?.email,
        bathroomPhoneNumber: data?.steerConcierge?.bathroomHelp?.phoneNumber,
        otherEmail: data?.steerConcierge?.other?.email,
        otherPhoneNumber: data?.steerConcierge?.other?.phoneNumber,
      }}
      name="control-hooks"
      layout="vertical"
      onFinish={onFinish}
    >
      <h3 className="label-style">Room Service</h3>
      <Row justify="space-between">
        <Col xs={7} md={11}>
          <Form.Item
            name="roomEmail"
            label="Email"
            // onChange={(e) => setPhoneNumber(e.target.value)}
          >
            <Input type="email" placeholder="Email" />
          </Form.Item>
        </Col>
        <Col xs={7} md={11}>
          <Form.Item
            name="roomPhoneNumber"
            label="Phone Number"
            // onChange={(e) => setPhoneNumber(e.target.value)}
          >
            <Input type="number" placeholder="Phone Number" />
          </Form.Item>
        </Col>
      </Row>
      <h3 className="label-style">Food Service</h3>
      <Row justify="space-between">
        <Col xs={7} md={11}>
          <Form.Item
            name="foodEmail"
            label="Email"
            // onChange={(e) => setPhoneNumber(e.target.value)}
          >
            <Input type="email" placeholder="Email" />
          </Form.Item>
        </Col>
        <Col xs={7} md={11}>
          <Form.Item
            name="foodPhoneNumber"
            label="Phone Number"
            // onChange={(e) => setPhoneNumber(e.target.value)}
          >
            <Input type="number" placeholder="Phone Number" />
          </Form.Item>
        </Col>
      </Row>
      <h3 className="label-style">Nursing Staff</h3>
      <Row justify="space-between">
        <Col xs={7} md={11}>
          <Form.Item
            name="nursingEmail"
            label="Email"
            // onChange={(e) => setPhoneNumber(e.target.value)}
          >
            <Input type="email" placeholder="Email" />
          </Form.Item>
        </Col>
        <Col xs={7} md={11}>
          <Form.Item
            name="nursingPhoneNumber"
            label="Phone Number"
            // onChange={(e) => setPhoneNumber(e.target.value)}
          >
            <Input type="number" placeholder="Phone Number" />
          </Form.Item>
        </Col>
      </Row>

      <h3 className="label-style">Bathroom Help</h3>
      <Row justify="space-between">
        <Col xs={7} md={11}>
          <Form.Item
            name="bathroomEmail"
            label="Email"
            // onChange={(e) => setPhoneNumber(e.target.value)}
          >
            <Input type="email" placeholder="Email" />
          </Form.Item>
        </Col>
        <Col xs={7} md={11}>
          <Form.Item
            name="bathroomPhoneNumber"
            label="Phone Number"
            // onChange={(e) => setPhoneNumber(e.target.value)}
          >
            <Input type="number" placeholder="Phone Number" />
          </Form.Item>
        </Col>
      </Row>

      <h3 className="label-style">Other</h3>
      <Row justify="space-between">
        <Col xs={7} md={11}>
          <Form.Item
            name="otherEmail"
            label="Email"
            // onChange={(e) => setPhoneNumber(e.target.value)}
          >
            <Input type="email" placeholder="Email" />
          </Form.Item>
        </Col>
        <Col xs={7} md={11}>
          <Form.Item
            name="otherPhoneNumber"
            label="Phone Number"
            // onChange={(e) => setPhoneNumber(e.target.value)}
          >
            <Input type="number" placeholder="Phone Number" />
          </Form.Item>
        </Col>
      </Row>

      <h3 className="label-style">Escalation Recipients</h3>
      <Row justify="space-between">
        <Col xs={7} md={11}>
          <Form.Item name="escalationRecipientsEmail" label="Email">
            <>
              {!emailInputVisible && (
                <Tag
                  onClick={() => showInput("email")}
                  className="site-tag-plus"
                >
                  <PlusOutlined /> New Email
                </Tag>
              )}
              {emailInputVisible && (
                <Input
                  ref={inputRef}
                  type="text"
                  size="medium"
                  placeholder="Email"
                  maxLength={50}
                  // rules={[{ required: true, message: "Only Number allowed" }]}
                  style={{
                    width: "100%",
                  }}
                  value={emailInputValue}
                  onChange={(e) => handleInputChange(e, "email")}
                  onBlur={handleInputConfirm}
                  onPressEnter={handleInputConfirm}
                />
              )}
              <div style={{ marginTop: 16 }}>
                <TweenOneGroup
                  enter={{
                    scale: 0.8,
                    opacity: 0,
                    type: "from",
                    duration: 100,
                  }}
                  onEnd={(e) => {
                    if (e.type === "appear" || e.type === "enter") {
                      e.target.style = "display: inline-block"
                    }
                  }}
                  leave={{
                    opacity: 0,
                    width: 0,
                    scale: 0,
                    duration: 200,
                  }}
                  appear={false}
                >
                  {EmailTagChild}
                </TweenOneGroup>
              </div>
            </>
          </Form.Item>
        </Col>

        <Col xs={7} md={11}>
          <Form.Item
            name="escalationRecipientsPhoneNumber"
            label="Phone Number"
          >
            <>
              {!phoneInputVisible && (
                <Tag
                  onClick={() => showInput("phoneNumber")}
                  className="site-tag-plus"
                >
                  <PlusOutlined /> New Phone Number
                </Tag>
              )}
              {phoneInputVisible && (
                <Input
                  ref={inputRef}
                  type="text"
                  size="medium"
                  placeholder="phone Number"
                  maxLength={50}
                  // rules={[{ required: true, message: "Only Number allowed" }]}
                  style={{
                    width: "100%",
                  }}
                  value={phoneInputValue}
                  onChange={(e) => handleInputChange(e, "phone")}
                  onBlur={handleInputConfirm}
                  onPressEnter={handleInputConfirm}
                />
              )}
              <div style={{ marginTop: 16 }}>
                <TweenOneGroup
                  enter={{
                    scale: 0.8,
                    opacity: 0,
                    type: "from",
                    duration: 100,
                  }}
                  onEnd={(e) => {
                    if (e.type === "appear" || e.type === "enter") {
                      e.target.style = "display: inline-block"
                    }
                  }}
                  leave={{
                    opacity: 0,
                    width: 0,
                    scale: 0,
                    duration: 200,
                  }}
                  appear={false}
                >
                  {PhoneTagChild}
                </TweenOneGroup>
              </div>
            </>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        wrapperCol={{
          offset: 11,
          span: 16,
        }}
      >
        <div>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </div>
      </Form.Item>
    </Form>
  )
}

export default SteerConcierge

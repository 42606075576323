import { Button, Col, Form, Input, notification, Row } from "antd";
import React, { useState } from "react";
import StorageService from "../../services/storage";

import APIService from "../../services/api";

const MeditechConfig = ({ id, data }) => {
  const [form] = Form.useForm();
  const { meditechConfig: currentMeditechConfig = {} } = data;
  const { locationId = ""} = currentMeditechConfig;
  
  const [meditechConfig, setMeditechConfig] = useState({
    locationId: locationId,
  });
  
  const openNotification = () => {
    notification.open({
      message: "Updated Successfully",
    });
  };
  const onFinish = () => {
    form
      .validateFields()
      .then(async (values) => {
        let userData = StorageService.getData("user");
        let requestObj = {
          ...values,
          userId: userData?._id,
          organizationId: id,
          organizationName: data?.name,
          organizationType: data?.organizationType,
          meditechConfig: {
            locationId: values?.locationId || "",
          },
        };
        const response = await APIService.requestWithToken(
          "api/v1/organization/addOrEditOrganization",
          "post",
          requestObj
        );

        if (
          response?.res?.message.includes("organization update successfully.")
        ) {
          openNotification();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Form
      form={form}
      initialValues={meditechConfig}
      name="control-hooks"
      layout="vertical"
      onFinish={onFinish}
    >
      <Row justify="space-between">
        <Col xs={7} md={11}>
          <Form.Item name="locationId" label="Location Id">
            <Input placeholder="Location Id" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <div>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
export default MeditechConfig;

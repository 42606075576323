import React, { useEffect } from "react"
import { compose } from "redux"
import { connect } from "react-redux"
// import {
//   Form,
//   Card,
//   Row,
//   Typography,
//   Col,
//   Input,
//   Button,
//   Layout,
//   Radio,
//   Descriptions,
//   DatePicker,
//   message,
// } from "antd"
import { withRouter, Redirect } from "react-router-dom"
// import APIService from "../../../services/api"
// import StorageService from "../../../services/storage"
// import ForgotPassword from "./forgotpassword"
// import {
//   ShoppingOutlined,
//   UserOutlined,
//   ExperimentOutlined,
// } from "@ant-design/icons"
// import { MOBILE_NUMBER_REGEX } from "../../../constants/common"
import { useAuth0 } from "@auth0/auth0-react"

// const { Header, Footer, Content } = Layout

function Login(props) {
  const { loginWithRedirect, user, isAuthenticated } = useAuth0()
  // let token = StorageService.getData("token")
  // const [loginForm] = Form.useForm()

  // const signIn = (values) => {
  //   APIService.request("api/v1/admin/login", "post", { ...values }).then(
  //     ({ res, headers }) => {
  //       if (res && res.code === "OK") {
  //         StorageService.setData("user", res.data.user)
  //         StorageService.setData("token", res.data.token)
  //         loginForm.resetFields()
  //         props.history.push("/app/user-management")
  //       }
  //     }
  //   )
  // }

  useEffect(() => {
    if (!isAuthenticated && !user) {
      loginWithRedirect()
    }
  }, [isAuthenticated, loginWithRedirect, user])

  return isAuthenticated && user ? (
    <Redirect to="/app/user-management"></Redirect>
  ) : (
    <></>
    // <Layout>
    //   <Content style={{ background: "blue", height: "calc(100vh - 70px)" }}>
    //     <Row justify="space-around" align="middle" style={{ height: "100%" }}>
    //       <Col span={6}>
    //         <Card title="Login">
    //           <Form
    //             layout="vertical"
    //             form={loginForm}
    //             className="login-form"
    //             onFinish={signIn}
    //           >
    //             <Form.Item
    //               className="m-b-5"
    //               name="email"
    //               rules={[
    //                 {
    //                   required: true,
    //                 },
    //               ]}
    //             >
    //               <Input placeholder="Email/Mobile Number" />
    //             </Form.Item>
    //             <Form.Item
    //               className="m-b-5"
    //               name="password"
    //               rules={[
    //                 {
    //                   required: true,
    //                 },
    //                 {
    //                   min: 8,
    //                 },
    //               ]}
    //             >
    //               <Input type="password" placeholder="Password" />
    //             </Form.Item>
    //             {/* <a
    //                   onClick={() =>
    //                     updateModalData({
    //                       visible: true,
    //                     })
    //                   }
    //                 >
    //                   Forgot Password?
    //                 </a> */}
    //             <div className="df jc">
    //               <Form.Item>
    //                 <Button htmlType="submit" type="primary">
    //                   Login
    //                 </Button>
    //               </Form.Item>
    //             </div>
    //           </Form>
    //         </Card>
    //       </Col>
    //     </Row>
    //   </Content>
    //   <Footer style={{ height: "70px" }}>
    //     <Row justify="space-around">
    //       {" "}
    //       <Col span="4">@2021Steer Health</Col>{" "}
    //     </Row>
    //   </Footer>
    // </Layout>
  )
}

function mapStateToProps(state) {
  return state
}
export default compose(withRouter, connect(mapStateToProps, null))(Login)

import React, { useEffect, useState } from "react";
import APIService from "../../services/api";
import { Table, Row, Space, Button, Typography, Select, Tabs, Spin, Modal, Col, Form } from "antd";
const { Title } = Typography;
function SelectData ({organizationList,fetching,updateOrganizations,initialValue,debounceFetcher}){
    const [filterOrganization, updateOrganization] = useState();
    useEffect(()=>{
        updateOrganizations(filterOrganization)
    },[filterOrganization])
    return (
        <Select
        showSearch
        allowClear
        style={{ width: 400 }}
        placeholder="Select an Organization"
        optionFilterProp="children"
        onChange={(newVal) => updateOrganization(newVal)}
        onSearch={(value) => debounceFetcher(value)}
        filterOption={false}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        value={filterOrganization || initialValue}
        options={organizationList}
      ></Select>
    )
  }

  export default SelectData
import React, { useEffect, useState } from "react";
import APIService from "../../services/api";
import { Table, Row, Space, Button, Typography, Select, Tabs, Spin, Modal, Col, Form } from "antd";
import SelectData from "./steerHealthDropDown";
import Search from "antd/lib/input/Search";
const { Title } = Typography;
function PrimeHealth() {
  const scrollHeight = window.innerHeight - 337;

  const [primeOrgList, setPrimeHealthList] = useState({
    dataSource: [],
  });
  const [organizationList, setOrganizationList] = useState([]);
  const [primeHealths,setPrimeHealths]=useState([])
  const [filterOrganization, updateOrganization] = useState();
  const [nameFilter,setFilter]=useState("")
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = React.useState(false);

  const columns = [
    {
      title: "Departments",
      dataIndex: "primeHealthOrgName",
    },
    {
      title: "Steer Health Organization",
      dataIndex: "",
      render: (obj) => {
        return (
          <SelectData
            fetching={fetching}
            updateOrganizations={updateOrganization}
            organizationList={organizationList}
            initialValue={obj.organizationId}
            debounceFetcher={debounceFetcher}
          ></SelectData>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "",
      width: 100,
      align: "center",
      render: (primeOrg) => {
        return (
          <Space>
            <Button type="primary" onClick={() => handleSaveBtn(primeOrg)}>
              {" "}
              Save
            </Button>
          </Space>
        );
      },
    },
  ];
  useEffect(()=>{
    primeHealthList();
  },[nameFilter])
  function handleSaveBtn(primeOrg) {
    let obj = {
      primeHealthOrgName: primeOrg.primeHealthOrgName,
      addSteerHealthOrgId: filterOrganization || primeOrg.organizationId,
      removeSteerHealthOrgId: primeOrg.organizationId,
    };
    setLoading(true);
    APIService.requestWithToken("api/v1/organization/primehealthMapping", "post", obj).then(({ res }) => {
      if (res && res.code === "OK") {
        primeHealthList();
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }
  function primeHealthList() {
    setLoading(false);
    APIService.requestWithToken("api/v1/organization/primeHealth", "get", {nameFilter:nameFilter}).then(({ res }) => {
      if (res && res.code === "OK") {
        setLoading(false);
        let primeHealthData = res.data?.organizationData.map((item)=>{
              return {label:item.primeHealthOrgName,value:item.primeHealthOrgName  }     
        })
        setPrimeHealths(primeHealthData||[])
        setPrimeHealthList({ dataSource: res.data?.organizationData });
      }
      else{
        setLoading(false);
      }
    });
  }
  const debounceFetcher = (searchStr) => {
    setFetching(true);
    let searchQuery = "";
    if (searchStr) {
      searchQuery = {
        name: { $regex: searchStr, $options: "i" },
      };
    }
    getOrganizationsData(searchQuery);
  };
  const getOrganizationsData = (searchQuery) => {
    APIService.requestWithToken("api/v1/admin/org/list", "get", {
      query: searchQuery ? JSON.stringify(searchQuery) : null,
      select: JSON.stringify(["_id", "name"]),
      sort: JSON.stringify({ name: 1, createdAt: -1 }),
    }).then(({ res }) => {
      if (res && res.code === "OK" && res.data.count) {
        setFetching(false);
        let orgList = res.data.list.map((a) => {
          return {
            label: a.name,
            value: a._id,
          };
        });
        setOrganizationList(orgList);
      }
    });
  };
  useEffect(() => {
    primeHealthList();
    getOrganizationsData();
  }, []);
  return (<>
        {!loading ? (
          <>
          <div className="df  je">
          <space>
               <Search
              placeholder="Select an Departments"
              onSearch={(value) => setFilter(value)}
              style={{ width: 200 }}
            />
          </space>
         </div>
         <div className="df  jc">
           <Table {...primeOrgList} columns={columns} onChange={primeHealthList} scroll={{ y: scrollHeight }}></Table>
           </div>
        
          </>
        ) : (
          <Spin />
        )}  
  </>
  );
}

export default PrimeHealth;

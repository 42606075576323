import React from 'react'
import { Row, Form, Col, Divider, Radio, Button, notification } from "antd";

import StorageService from "../../services/storage";
import APIService from "../../services/api";

function NewConsultTimeSlotsDifferenceTab({ data, id }) {
  const [form] = Form.useForm();
  const { consultTimeConfig } = data

  const openNotification = () => {
    notification.open({
      message: 'Updated Successfully',
    });
  };

  const onSettingFinish = () => {
    form
      .validateFields()
      .then((values) => {
        let userData = StorageService.getData('user')
        let requestObj = {
          consultTimeConfig: {
            newPatient: values.newPatient,
            existsPatient: values.existsPatient,
          },
          userId: userData?._id,
          organizationId: id,
          organizationName: data?.name,
          organizationType: data?.organizationType,
        }
        APIService.requestWithToken("api/v1/organization/addOrEditOrganization", "post", requestObj)
      }).finally(() => {
        openNotification()
      })
  };
  return (
    <>
      <Form
        form={form}
        layout="vertical"
        name="control-hooks"
        initialValues={{
          newPatient: !isNaN(consultTimeConfig?.newPatient) ?
              parseInt(consultTimeConfig?.newPatient) : undefined,
          existsPatient: !isNaN(consultTimeConfig?.existsPatient) ?
              parseInt(consultTimeConfig?.existsPatient) : undefined,
        }}
        onFinish={onSettingFinish}
      >
        <Row justify="space-between">
          <Col xs={7} md={11}>
            <Form.Item
              name="newPatient"
              label="New Patient"
            >
              <Radio.Group>
                <Radio.Button value={15}>15 Min</Radio.Button>
                <Radio.Button value={20}>20 Min</Radio.Button>
                <Radio.Button value={30}>30 Min</Radio.Button>
                <Radio.Button value={45}>45 Min</Radio.Button>
                <Radio.Button value={60}>60 Min</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Divider type="vertical" />
          <Col xs={7} md={11}>
            <Form.Item
              name="existsPatient"
              label="Exists Patient"
            >
              <Radio.Group>
                <Radio.Button value={15}>15 Min</Radio.Button>
                <Radio.Button value={20}>20 Min</Radio.Button>
                <Radio.Button value={30}>30 Min</Radio.Button>
                <Radio.Button value={45}>45 Min</Radio.Button>
                <Radio.Button value={60}>60 Min</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          wrapperCol={{
            offset: 11,
            span: 16,
          }}
        >
          <div>
            <Button type="primary" htmlType="submit" style={{ marginTop: "3.5rem" }} >
              Save
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  )
}

export default NewConsultTimeSlotsDifferenceTab

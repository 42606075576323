import React from "react"
import { Tabs } from "antd"
import SteerConcierge from "./steerConcierge"
import ConfigInsurance from "./ConfigInsurance"

const { TabPane } = Tabs

const Configuration = ({ data, id }) => {
  return (
    <div>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Steer Concierge" key="1">
          <SteerConcierge id={id} data={data} />
        </TabPane>
        <TabPane tab="Insurance" key="2">
          <ConfigInsurance id={id} data={data} />
        </TabPane>
      </Tabs>
    </div>
  )
}

export default Configuration

const variables = {
  BASE_URL: process.env.REACT_APP_BASE_URL,
  NODE_ENV: process.env.REACT_APP_NODE_ENV,
  GOOGLE_MY_BUSINESS: "https://mybusinessbusinessinformation.googleapis.com/v1",
  GOOGLE_MY_BUSINESS_ACCOUNTID: "104086650069834702393",
  CLIENT_ID:
    "788033178265-n2ffngeena8a48qegt8fhi8bu5k5edgh.apps.googleusercontent.com",
  APPV2_URL: process.env.REACT_APP_APPV2_URL,
  REFRESH_TOKEN:
    "1//0gBX9niD6yuDJCgYIARAAGBASNwF-L9IrH5H2Z9LdPc0G-YacDttLXcae1nhuy_4Mnzr6-hOnpcbTsV9sh1GmMcfw3H6nikaXB28",
  REDIRECT_URL: "http://localhost:3000/",
  GRANT_TYPE: "refresh_token",
  CLIENT_SECRET: "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",
  AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN,
  AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  PROVIDER_SYNC_URL: process.env.REACT_APP_PROVIDER_SYNC_URL,
};

export default variables;

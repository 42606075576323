import React from "react"
import { Input, Row, Form, Col, Button, notification } from "antd"
import StorageService from "../../services/storage"
import APIService from "../../services/api"

function IVRConfig({ data, id }) {
    const [form] = Form.useForm()
    const openNotification = () => {
        notification.open({
            message: "Updated Successfully",
        })
    }
    const onSettingFinish = () => {
        form
            .validateFields()
            .then((values) => {
                let userData = StorageService.getData("user")
                let requestObj = {
                    ...values,
                    IVRConfig: {
                        ivrNumber: values?.ivrNumber,
                        orgOfficeHours: values?.orgOfficeHours,
                        orgOfficeNumber: values?.orgOfficeNumber,
                        orgBillingNumber: values?.orgBillingNumber,
                        gcnShortLink: values?.gcnShortLink,
                        googleDirectionsShortLink: values?.googleDirectionsShortLink,
                        orgDisplayName: values?.orgDisplayName,
                    },
                    userId: userData?._id,
                    organizationId: id,
                    organizationName: data?.name,
                    organizationType: data?.organizationType,
                }
                APIService.requestWithToken(
                    "api/v1/organization/addOrEditOrganization",
                    "post",
                    requestObj
                )
            })
            .finally(() => {
                openNotification()
            })
    }

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                name="control-hooks"
                initialValues={data?.IVRConfig}
                onFinish={onSettingFinish}
            >
                <Row justify="space-between">
                    <Col xs={7} md={11}>
                        <Form.Item name="ivrNumber" label="IVR Number">
                            <Input placeholder="IVR Number" />
                        </Form.Item>
                    </Col>
                    <Col xs={7} md={11}>
                        <Form.Item name="orgOfficeHours" label="Organization Office Hours">
                            <Input placeholder="Organization Office Hours" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="space-between">
                    <Col xs={7} md={11}>
                        <Form.Item name="orgOfficeNumber" label="Organization Office Number">
                            <Input placeholder="Organization Office Number" />
                        </Form.Item>
                    </Col>
                    <Col xs={7} md={11}>
                        <Form.Item name="orgBillingNumber" label="Organization Billing Number">
                            <Input placeholder="Organization Billing Number" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="space-between">
                    <Col xs={7} md={11}>
                        <Form.Item name="gcnShortLink" label="GCN Short Link">
                            <Input placeholder="GCN Short Link" />
                        </Form.Item>
                    </Col>
                    <Col xs={7} md={11}>
                        <Form.Item name="googleDirectionsShortLink" label="Google Directions Short Link">
                            <Input placeholder="Google Directions Short Link" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="space-between">
                    <Col xs={7} md={11}>
                        <Form.Item name="orgDisplayName" label="Organization Display Name">
                        <Input placeholder="Organization Display Name" />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    wrapperCol={{
                        offset: 11,
                        span: 16,
                    }}
                >
                    <div>
                        <Button type="primary" htmlType="submit">
                            Save
                        </Button>
                    </div>
                </Form.Item>
            </Form>

        </>
    )
}

export default IVRConfig

import React from 'react'
import { Input, Row, Form, Col, Button } from "antd";


function EpicStep({ next, setStep2Data, prev, skip, step2Data }) {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    setStep2Data(values)
    next()
  }

  return (
    <Form
      form={form}
      layout="vertical"
      name="control-hooks"
      onFinish={onFinish}
      initialValues={step2Data}
    >
      <Row justify="space-between">
        <Col xs={7} md={11}>
          <Form.Item
            name="gcnRequestInBasket"
            label="GetCareNow Request InBasket"
            rules={[
              {
                min: 3,
                max: 25,
                message: "between 3 and 25 characters required"
              },
            ]}
          >
            <Input
              placeholder="GetCareNow Request InBasket"
            />
          </Form.Item>
        </Col>
        <Col xs={7} md={11}>
          <Form.Item
            name="gcnRequestEmail"
            label="GetCareNow Request Email"
            rules={[
              {
                min: 3,
                max: 28,
                message: "between 3 and 28 characters required"
              },
            ]}
          >
            <Input
              placeholder="GetCareNow Request Email" maxLength={28}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="space-between">
        <Col xs={7} md={11}>
          <Form.Item
            name="pxIncomingInBasket"
            label="Px Incoming InBasket"
            rules={[
              {
                min: 3,
                max: 25,
                message: "between 3 and 25 characters required"
              },
            ]}
          >
            <Input
              placeholder="Px Incoming InBasket" maxLength={6}
            />
          </Form.Item>
        </Col>
        <Col xs={7} md={11}>
          <Form.Item
            name="pxIncomingEmail"
            label="Px Incoming Email"
            rules={[
              {
                min: 3,
                max: 28,
                message: "between 3 and 28 characters required"
              },
            ]}
          >
            <Input
              placeholder="Px Incoming Email" maxLength={28}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="space-between">
        <Col xs={7} md={11}>
          <Form.Item
            name="mktgIncomingInBasket"
            label="Mktg Incoming InBasket"
            rules={[
              {
                min: 3,
                max: 25,
                message: "between 3 and 25 characters required"
              },
            ]}
          >
            <Input
              placeholder="Mktg Incoming InBasket" maxLength={6}
            />
          </Form.Item>
        </Col>

        <Col xs={7} md={11}>
          <Form.Item
            name="mktgIncomingEmail"
            label="Mktg Incoming Email"
            rules={[
              {
                min: 3,
                max: 28,
                message: "between 3 and 28 characters required"
              },
            ]}
          >
            <Input
              placeholder="Mktg Incoming Email" maxLength={28}
            /></Form.Item>
        </Col>
      </Row>

      <Row justify="space-between">
        <Col xs={7} md={11}>
          <Form.Item
            name="ptIncomingInBasket"
            label="Pt Outreach Incoming InBasket"
            rules={[
              {
                min: 3,
                max: 25,
                message: "between 3 and 25 characters required"
              },
            ]}
          >
            <Input
              placeholder="Pt Outreach Incoming InBasket" maxLength={6}
            />
          </Form.Item>
        </Col>

        <Col xs={7} md={11}>
          <Form.Item
            name="way2SmsChatIncomingInBasket"
            label="2Way SMS chat Incoming InBasket"
            rules={[
              {
                min: 3,
                max: 25,
                message: "between 3 and 25 characters required"
              },
            ]}
          >
            <Input
              placeholder="2Way SMS chat Incoming InBasket" maxLength={28}
            /></Form.Item>
        </Col>
      </Row>

      <Row justify="space-between">
        <Col xs={7} md={11}>
          <Form.Item
            name="referralRequestInBasket"
            label="Referral Request InBasket"
            rules={[
              {
                min: 3,
                max: 25,
                message: "between 3 and 25 characters required"
              },
            ]}
          >
            <Input
              placeholder="Referral Request InBasket" maxLength={6}
            />
          </Form.Item>
        </Col>

        <Col xs={7} md={11}>
          <Form.Item
            name="carePlusIncomingInBasket"
            label="CarePlus Incoming InBasket"
            rules={[
              {
                min: 3,
                max: 25,
                message: "between 3 and 25 characters required"
              },
            ]}
          >
            <Input placeholder="CarePlus Incoming InBasket" />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="space-between">
        <Col xs={7} md={11}>
          <Form.Item
            name="eRegistrationEmail"
            label="eRegistration Email"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                message: 'Please input your E-mail!',
              },
            ]}
          >
            <Input
              placeholder="eRegistration Email" maxLength={28}
            />
          </Form.Item>
        </Col>

        <Col xs={7} md={11}>
          <Form.Item
            name="eRegistrationInBasket"
            label="eRegistration In Basket"
            rules={[
              {
                min: 3,
                max: 25,
                message: "between 3 and 25 characters required"
              },
            ]}
          >
            <Input placeholder="eRegistration In Basket" maxLength={6} />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="space-between">
        <Col xs={7} md={11}>
          <Form.Item
            name="consultRequestEmail"
            label="Consult Request Email"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                message: 'Please input your E-mail!',
              },
            ]}
          >
            <Input
              placeholder="Consult Request Email" maxLength={28}
            />
          </Form.Item>
        </Col>

        <Col xs={7} md={11}>
          <Form.Item
            name="consultRequestInBasket"
            label="Consult Request In Basket"
            rules={[
              {
                min: 3,
                max: 25,
                message: "between 3 and 25 characters required"
              },
            ]}
          >
            <Input placeholder="Consult Request In Basket" maxLength={6} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        wrapperCol={{
          offset: 9,
          span: 16,
        }}
      >
        <div>
          <Button
            style={{ margin: '0 8px' }}
            onClick={() => prev()}
          >
            Previous
          </Button>
          <Button type="primary" htmlType="submit">
            Next
          </Button>
          <Button
            style={{ margin: '0 8px' }}
            onClick={() => skip()}>
            Skip
          </Button>
        </div>
      </Form.Item>
    </Form>
  )
}

export default EpicStep

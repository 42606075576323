import React from "react"
import { Tabs } from "antd"

// import OutboundTab from "./OutboundTab"
import InBasket from "./InBasket"

function EpicTab({ data, id }) {
  const { TabPane } = Tabs

  return (
    <>
      <div>
        <Tabs defaultActiveKey="1">
          <TabPane tab="InBasket" key="1">
            <InBasket data={data} id={id} />
          </TabPane>
          {/* <TabPane tab="Outbound" key="2">
            <OutboundTab data={data} id={id} />
          </TabPane> */}
        </Tabs>
      </div>
    </>
  )
}

export default EpicTab

import React, { useEffect, useState } from "react";
import APIService from "../../services/api";
import { Table, Space, Button, Typography, Spin} from "antd";
const { Title } = Typography;
function GoogleMyBusiness (){
  const [locationData,setLocationData] = React.useState(
   { dataSource:[]}
  )
  const [loading,setLoading]=useState(false)
  const scrollHeight = window.innerHeight - 337;
  const columns = [
    {
      title: "Organization Name",
      dataIndex: "title",
    },
    {
        title: "Location Id",
        dataIndex: "",
        render: (obj) => {
            return obj.name.split("/")[1]
          },
    },
    {
        title: "Action",
        width: 100,
        align: "center",
        render: (obj) => {
          return (
            <Space>
              <Button type="primary" onClick={() => {
                navigator.clipboard.writeText(obj.name.split("/")[1])}
                }>
                {" "}
                Copy
              </Button>
            </Space>
          )
        }
    },
  ];
  const getGoogleMyBusinessData = async (searchQuery) => {
    setLoading(true)
    let token = await APIService.googleMyBusinessToken()
    if(token){
        let locations = await APIService.googleMyBusinessLocations(token.res.access_token)
        setLocationData({dataSource:locations.data})
        setLoading(false)
    }
};
useEffect(() => {
    getGoogleMyBusinessData()
  }, []);
    return (<>
       <Title level={3} style={{flex: 1}}> Google My business</Title>
       <div className="df  jc">
       {!loading ? (<div className="df  am">
               <Table {...locationData} columns={columns} onChange={""} scroll={{ y: scrollHeight }}></Table>
        </div>):(
          <Spin/>
        )}
       </div>
        
        </>
    )
}
export default GoogleMyBusiness;
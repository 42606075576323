export default (state = [], action) => {
  switch (action.type) {
    case "COMMON_SOCKET_INIT":
      return { ...state, socketConnection: action.socket };
    case "CHAT_SOCKET_INIT":
      return { ...state, chatSocketConnection: action.socket };
    default:
      return state;
  }
};

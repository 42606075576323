import React, { useEffect, useState } from "react";
import { Input, Select, Modal, Row, Form, Col, Spin } from "antd";
import APIService from "../../services/api";
const { Option } = Select;
function AddEdit({ modalData, setModalData }) {
    const [masterform] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useState();
    useEffect(() => {
        setLoading(true);
        if (modalData.data) {

            setInitialValues({
                ...modalData.data
            });
        } else {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (initialValues) {
            setLoading(false);
        }
    }, [initialValues]);

    const handleCancel = () => {
        setModalData({ visible: false });
    };


    const onFinish = (values) => {
        setLoading(true);
        let requestObj = values;
        requestObj.parent_code = modalData.parent_code;

        if (modalData?.data?._id) {
            APIService.requestWithToken(
                "api/v1/admin/master/" + modalData.data._id,
                "put",
                requestObj
            ).then(({ res }) => {
                if (res && res.code === "OK") {
                    setModalData({ visible: false, success: true });
                    setLoading(false);
                }
            });
        } else {
            APIService.requestWithToken("api/v1/admin/master", "post", requestObj).then(
                ({ res }) => {
                    if (res && res.code === "OK") {
                        setModalData({ visible: false, success: true });
                    }
                }
            );
        }
    };
    return (
        <Modal
            width={800}
            title={modalData?.data?._id ? "Update Master" : "Add Master"}
            visible={modalData.visible}
            okText="Submit"
            cancelText="Cancel"
            onOk={() => {
                masterform
                    .validateFields()
                    .then((values) => {
                        masterform.resetFields();
                        onFinish(values);
                    })
                    .catch((info) => {
                        console.log("Validate Failed:", info);
                    });
            }}
            onCancel={handleCancel}
        >
            {!loading ? (
                <Form
                    form={masterform}
                    initialValues={initialValues}
                    formLayout = 'vertical'
                    name="control-hooks"
                    onFinish={onFinish}
                >
                    <Row justify="space-between">
                        <Col xs={12} md={13}>
                            <Form.Item
                                name="title"
                                label="Title"
                                rules={[{ required: true }]}
                            >
                                <Input placeholder="Title" />
                            </Form.Item>
                        </Col>
                        <Col xs={12} md={13}>
                            <Form.Item
                                name="value"
                                label="Value"
                            >
                                <Input placeholder="Value" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            ) : (
                <Spin />
            )}
        </Modal>
    );
}

export default AddEdit;

import React from 'react'
import { Input, Row, Form, Col, Button } from "antd";

function HospitalChatBotStep({ next, setStep3Data, prev, skip, step3Data }) {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    setStep3Data(values)
    next()
  }

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        name="control-hooks"
        onFinish={onFinish}
        initialValues={step3Data}
      >
        <Row justify="space-between">
          <Col xs={7} md={11}>
            <Form.Item
              name="locationUrl"
              label="Find a Location URL"
            >
              <Input
                placeholder="Find a Location URL"
              />
            </Form.Item>
          </Col>

          <Col xs={7} md={11}>
            <Form.Item
              name="doctorUrl"
              label="Find a Doctor URL"
            >
              <Input placeholder="Find a Doctor URL" />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col xs={7} md={11}>
            <Form.Item
              name="patientForm"
              label="Patient Forms"
            >
              <Input
                placeholder="Patient Forms"
              />
            </Form.Item>
          </Col>

          <Col xs={7} md={11}>
            <Form.Item
              name="referralUrl"
              label="Submit a Referral URL"
            >
              <Input placeholder="Submit a Referral URL" />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col xs={7} md={11}>
            <Form.Item
              name="patientServices"
              label="Patient Services"
            >
              <Input
                placeholder="Patient Services"
              />
            </Form.Item>
          </Col>
          <Col xs={7} md={11}>
                  <Form.Item
                    name="billPay"
                    label="Bill Pay"
                  >
                    <Input
                      placeholder="Bill Pay"
                    />
                  </Form.Item>
                </Col>
        </Row>

        <Form.Item
          wrapperCol={{
            offset: 9,
            span: 16,
          }}
        >
          <div>
            <Button
              style={{ margin: '0 8px' }}
              onClick={() => prev()}
            >
              Previous
            </Button>
            <Button type="primary" htmlType="submit">
              Next
            </Button>
            <Button
              style={{ margin: '0 8px' }}
              onClick={() => skip()}>
              Skip
            </Button>
          </div>
        </Form.Item>

      </Form>
    </>

  )
}

export default HospitalChatBotStep

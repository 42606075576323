import React, { useEffect, useState } from "react";
import {
  Input,
  Select,
  Modal,
  Row,
  Form,
  Col,
  Spin,
} from "antd";
import APIService from "../../services/api";
import { userGroups, userGlobalRoles } from "../../constants/common";
const { Option } = Select;
function AddEditUSer({ modalData, setModalData }) {
  const [userform] = Form.useForm();
  const [organizationList, setOrganizationList] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [value, setValue] = React.useState([]);
  const [isDoctor, setIsDoctor] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState();
  useEffect(() => {
    setLoading(true);
    getOrganizationsData();
    if (modalData.data && Object.keys(modalData.data).length) {
      let orgRoles = { ...modalData.data.roles };
      delete orgRoles.__global_roles__;
      let roles = Object.keys(orgRoles);
      let orgs = [...new Set(Object.values(orgRoles).flat(1))];
      if (orgs.length) {
        getOrganizationsData({ _id: { $in: orgs } });
      }
      setInitialValues({
        ...modalData.data.profile,
        roles,
        __global_roles__: modalData?.data?.roles?.__global_roles__,
        phoneNumber: modalData?.data?.profile?.phoneNumber
          ? modalData?.data?.profile?.phoneNumber
          : modalData?.data?.profile?.phoneNumbers
            ? modalData?.data?.profile?.phoneNumbers[0]
            : '',
        email: modalData?.data?.emails[0]?.address,
        organizations: orgs,
      });
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (initialValues) {
      setLoading(false);
    }
  }, [initialValues]);

  const handleCancel = () => {
    setModalData({ visible: false });
  };

  const onFinish = (values) => {
    setLoading(true);
    let requestObj = values;
    requestObj.phoneNumbers = [values.phoneNumber]
    if (modalData?.data?._id) {
      APIService.requestWithToken(
        "api/v1/user/" + modalData.data._id,
        "put",
        requestObj
      ).then(({ res }) => {
        if (res && res.code === "OK") {
          setModalData({ visible: false, success: true });
          setLoading(false);
        }
      });
    } else {
      APIService.requestWithToken("api/v1/user", "post", requestObj).then(
        ({ res }) => {
          if (res && res.code === "OK") {
            setModalData({ visible: false, success: true });
            setLoading(false);
          }
        }
      );
    }
  };

  function onChange(value) {
    setIsDoctor(false);
    if (value.includes("doctor")) {
      setIsDoctor(true);
    }
  }

  const debounceFetcher = (searchStr) => {
    setFetching(true);
    let searchQuery = "";
    if (searchStr) {
      searchQuery = { name: { $regex: searchStr, $options: "i" } };
    }
    getOrganizationsData(searchQuery);
  };

  const getOrganizationsData = (searchQuery) => {
    APIService.requestWithToken("api/v1/organization", "get", {
      query: searchQuery ? JSON.stringify(searchQuery) : '{"$or": [{ "deactivated": false }, { "deactivated": { "$exists": false } }] }',
      select: JSON.stringify(["_id", "name", "deactivated"]),
      sort: JSON.stringify({ name: 1, createdAt: -1 }),
    }).then(({ res }) => {
      if (res && res.code === "OK") {
        setFetching(false);
        let orgList = res.data.list.map((a) => {
          return {
            label: a.name,
            value: a._id,
          };
        });
        setOrganizationList(orgList);
      }
    });
  };
  return (
    <Modal
      width={800}
      title={modalData?.data?._id ? "Update User" : "Add User"}
      visible={modalData.visible}
      okText="Submit"
      cancelText="Cancel"
      onOk={() => {
        userform
          .validateFields()
          .then((values) => {
            userform.resetFields();
            onFinish(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      onCancel={handleCancel}
    >
      {!loading ? (
        <Form
          form={userform}
          initialValues={initialValues}
          name="control-hooks"
          layout="vertical"
          onFinish={onFinish}
        >
          <Row justify="space-between">
            <Col xs={7} md={7}>
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[{ required: true }]}
              >
                <Input placeholder="First Name" />
              </Form.Item>
            </Col>
            <Col xs={7} md={7}>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[{ required: true, message: "Last Name is required." }]}
              >
                <Input placeholder="Last Name" />
              </Form.Item>
            </Col>
            <Col xs={7} md={9}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: "Email is required." },
                  {
                    type: "email",
                    message: "Email is not a valid email!",
                  },
                ]}
              >
                <Input placeholder="Email" type="email" readOnly={modalData?.data && Object.keys(modalData?.data).length} />
              </Form.Item>
            </Col>
            <Col xs={7} md={7}>
              <Form.Item
                name="title"
                label="Title"
                rules={[{ required: true, message: "Title is required." }]}
              >
                <Input placeholder="Title" />
              </Form.Item>
            </Col>
            <Col xs={7} md={7}>
              <Form.Item
                name="phoneNumber"
                label="Phone Number"
                rules={[
                  { required: true, message: "Phone no is required." },
                  {
                    min: 10,
                    message: "Please enter valid Phone Number!",
                  },
                  {
                    max: 10,
                    message: "Please enter valid Phone Number!",
                  },
                ]}
              >
                <Input placeholder="Phone Number" />
              </Form.Item>
            </Col>
            <Col xs={7} md={9}>
              <Form.Item
                name="npi"
                label="NPI"
              // rules={[{ required: isDoctor, message: "NPI is required." }]}
              >
                <Input placeholder="NPI" />
              </Form.Item>
            </Col>
            <Col xs={7} md={7}>
              <Form.Item
                name="roles"
                label="Role"
                rules={[{ required: true, message: "Role is required." }]}
              >
                <Select
                  showSearch
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Select a Role"
                  optionFilterProp="children"
                  onChange={onChange}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {Object.entries(userGroups).map((group) => (
                    <>
                      {group[1] !== "allUsers" && (
                        <Option key={group[0]} value={group[1]}>{`${group[0]
                          .replace(/([A-Z])/g, " $1")
                          .trim()
                          .toUpperCase()}`}</Option>
                      )}
                    </>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={7} md={16}>
              <Form.Item
                name="organizations"
                label="Organizations"
                rules={[
                  { required: true, message: "Organizations is required." },
                ]}
              >
                <Select
                  mode="multiple"
                  placeholder="Select organizations"
                  filterOption={false}
                  onSearch={(value) => debounceFetcher(value)}
                  notFoundContent={fetching ? <Spin size="small" /> : null}
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                  }}
                  style={{
                    width: "100%",
                  }}
                  options={organizationList}
                />
              </Form.Item>
            </Col>
            <Col xs={7} md={7}>
              <Form.Item
                name="__global_roles__"
                label="Global Roles"
              >
                <Select
                  showSearch
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Select a Global Role"
                  optionFilterProp="children"
                  onChange={onChange}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {Object.keys(userGlobalRoles).map((group) => (
                    <Option key={group} value={group}>{`${group
                      .replace(/([A-Z])/g, " $1")
                      .trim()
                      .toUpperCase()}`}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

          </Row>
        </Form>
      ) : (
        <Spin />
      )}
    </Modal>
  );
}

export default AddEditUSer;

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import configureStore from './Redux/stores/configureStore'
import { Auth0Provider } from "@auth0/auth0-react"
import history from "./utils/history"
import variables from "../src/config/index"

const { AUTH0_DOMAIN, AUTH0_CLIENT_ID } = variables
const store = configureStore()

const onRedirectCallback = () => {
  history.push("/app/user-management")
}

const providerConfig = {
  domain: AUTH0_DOMAIN,
  clientId: AUTH0_CLIENT_ID,
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: window.location.origin,
  },
}

ReactDOM.render(
  <BrowserRouter>
    <Auth0Provider {...providerConfig}>
      <Provider store={store}>
        <App />
      </Provider>
    </Auth0Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { useEffect, useState } from "react"
import {
  Input,
  Button,
  Form,
  notification,
  Select,
  Table,
  Popconfirm,
  Space,
  Switch,
} from "antd"
import { DeleteOutlined } from "@ant-design/icons"
import StorageService from "../../services/storage"
import APIService from "../../services/api"
import { Option } from "antd/lib/mentions"

const UnifiedHospitals = ({ data: propsData, id }) => {
  const outboundMessages = propsData?.unifiedHospitals
  let outbound = []
  // eslint-disable-next-line no-unused-expressions
  outboundMessages?.map((data, key) => {
    const obj = {
      key: key.toString(),
      linkedOrgId: data?.linkedOrgId,
      type: data?.type,
      useExternalForm: data?.useExternalForm,
      formName: data?.formName,
      formLink: data?.formLink,
    }

    return outbound.push(obj)
  })
  const [form] = Form.useForm()
  const [data, setData] = useState(outbound)
  const [editingKey, setEditingKey] = useState("")
  const [count, setCount] = useState(2)
  const [organizationData, setOrganizationData] = useState([])
  const [externalForm, setExternalForm] = useState(false)

  function primeHealthList() {
    APIService.requestWithToken("api/v1/admin/organizations", "get").then(
      ({ res }) => {
        if (res && res.code === "OK") {
          setOrganizationData(res?.data)
        } else {
        }
      }
    )
  }

  useEffect(() => {
    primeHealthList()
  }, [])

  if (organizationData?.length <= 0) return false

  const onChange = () => {
    setExternalForm(!externalForm)
  }

  const columns = [
    {
      title: "use external form",
      dataIndex: "useExternalForm",
      editable: true,
      render: (text, record) => {
        return (
          <Switch
            disabled={true}
            checked={
              record?.useExternalForm ||
              (externalForm && record?.key === editingKey)
            }
          />
        )
      },
    },
    {
      title: "Linked Org Id",
      dataIndex: "linkedOrgId",
      width: "15%",
      editable: true,
      render: (text, record) => {
        return organizationData.filter(
          (item) => item?._id === record?.linkedOrgId
        )[0]?.name
      },
    },
    {
      title: "Form Name",
      dataIndex: "formName",
      // width: "15%",
      editable: true,
    },
    {
      title: "Form Link",
      dataIndex: "formLink",
      // width: "15%",
      editable: true,
    },
    {
      title: "Type",
      dataIndex: "type",
      width: "15%",
      editable: true,
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      render: (_, record, key) => {
        const editable = isEditing(record)
        return (
          <>
            <Space size="middle">
              {editable ? (
                <span style={{ display: "flex" }}>
                  <Popconfirm
                    title="Sure to save?"
                    onClick={() => save(record.key)}
                  >
                    <Button
                      type="primary"
                      style={{
                        marginRight: 8,
                        cursor: "pointer",
                      }}
                    >
                      Save
                    </Button>
                  </Popconfirm>
                  <Popconfirm
                    title="Sure to cancel?"
                    onConfirm={() => cancel(record)}
                  >
                    <Button style={{ cursor: "pointer" }}>Cancel</Button>
                  </Popconfirm>
                </span>
              ) : (
                <Button
                  disabled={editingKey !== ""}
                  onClick={() => edit(record, key)}
                >
                  Edit
                </Button>
              )}
              <div
                onClick={() => handleDelete(record, key)}
                style={{ cursor: "pointer" }}
              >
                <DeleteOutlined />
              </div>
            </Space>
          </>
        )
      },
    },
  ]

  const handleDelete = (record, key) => {
    setData(data.filter((item) => item.key !== record.key))
  }

  const openNotification = () => {
    notification.open({
      message: "Updated Successfully",
    })
  }

  const types = [
    { value: "Emergency Room" },
    { value: "Urgent Care" },
    { value: "Primary Care" },
    { value: "Cardiology" },
    { value: "Oncology" },
    { value: "Pulmonary" },
    { value: "Neurology" },
    { value: "Surgery" },
    { value: "Radiology" },
    { value: "ASC" },
  ]

  const isEditing = (record) => record.key === editingKey

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      dataIndex === "linkedOrgId" ? (
        <Select
          showSearch
          style={{ width: "100%" }}
          placeholder="Enter Linked Org Id"
          optionFilterProp="children"
          disabled={externalForm ? true : false}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {organizationData.map((group, key) => (
            <Option key={key} value={group._id}>
              {group?.name}
            </Option>
          ))}
        </Select>
      ) : dataIndex === "type" ? (
        <Select
          showSearch
          style={{ width: "100%" }}
          placeholder="Types"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {types.map((group, key) => (
            <Option key={key} value={group.value}>
              {group?.value}
            </Option>
          ))}
        </Select>
      ) : dataIndex === "useExternalForm" ? (
        <Switch
          onChange={(e) => onChange(e, record)}
          checked={externalForm && +record?.key === +editingKey}
          disabled={editingKey !== record.key ? true : false}
        />
      ) : dataIndex === "formName" || dataIndex === "formLink" ? (
        <Input min={3} disabled={!externalForm ? true : false} />
      ) : (
        ""
      )

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    )
  }

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    }
  })

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    })
    setExternalForm(record?.useExternalForm)
    setEditingKey(record.key)
  }

  const cancel = () => {
    setEditingKey("")
  }

  const save = async (key) => {
    try {
      const row = await form.validateFields()
      const newData = [...data]
      const index = newData.findIndex((item) => key === item.key)
      if (index > -1) {
        const item = newData[index]
        newData.splice(index, 1, {
          ...item,
          ...row,
        })
        setData(newData)
        setEditingKey("")
      } else {
        newData.push(row)
        setData(newData)
        setEditingKey("")
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo)
    }
  }
  const handleAdd = () => {
    const newData = {
      key: (data.length + 1).toString(),
      linkedOrgId: "",
      type: "",
      useExternalForm: false,
      formName: "",
      formLink: "",
    }

    setData((oldValue) => JSON.parse(JSON.stringify([...oldValue, newData])))
    setCount(count + 1)
    edit(newData)
  }
  const onFinish = () => {
    form
      .validateFields()
      .then(async () => {
        let userData = StorageService.getData("user")

        const modifiedData = data?.map((item) => {
          return {
            linkedOrgId: item.linkedOrgId,
            type: item?.type,
            useExternalForm: item?.useExternalForm,
            formName: item?.formName,
            formLink: item?.formLink,
          }
        })

        let dataObj = {
          userId: userData?._id,
          organizationId: id,
          organizationName: propsData?.name,
          organizationType: propsData?.organizationType,
        }
        const response = await APIService.requestWithToken(
          "api/v1/organization/addOrEditOrganization",
          "post",
          { unifiedHospitals: modifiedData, ...dataObj }
        )
        if (
          response?.res?.message.includes("organization update successfully.")
        ) {
          openNotification()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  return (
    <>
      <Form
        form={form}
        layout="vertical"
        name="control-hooks"
        initialValues={{
          ...propsData,
          linkedOrgId: propsData?.UnifiedHospital?.linkedOrgId,
          type: propsData?.UnifiedHospital?.type,
        }}
        onFinish={onFinish}
      >
        <div>
          <Button
            onClick={handleAdd}
            type="primary"
            style={{
              marginBottom: 16,
              float: "right",
            }}
          >
            Add
          </Button>
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            bordered
            dataSource={data}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={false}
          />
        </div>

        <Form.Item
          wrapperCol={{
            offset: 11,
            span: 16,
          }}
        >
          <div style={{ marginTop: "20px" }}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  )
}

export default UnifiedHospitals

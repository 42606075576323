import React, { useEffect, useRef, useState } from 'react'

import { TweenOneGroup } from 'rc-tween-one'

import {
  Row,
  Col,
  Tag,
  Form,
  Input,
  Button,
  Typography,
  notification,
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import './index.css'
import APIService from '../../services/api'
import StorageService from '../../services/storage'

const { Title } = Typography

function CommonConfiguration() {
  const inputRef = useRef(null)

  const [form] = Form.useForm()
  const [tags, setTags] = useState([])
  const [loading, setLoading] = useState(false)
  const [configData, setConfigData] = useState({})
  const [inputValue, setInputValue] = useState('')
  const [inputVisible, setInputVisible] = useState(false)
  const [configId, setConfigId] = useState('')

  useEffect(() => {
    setLoading(true)
    APIService.requestWithToken(
      `api/v1/organization/fetch-common-config`,
      'get'
    )
      .then((res) => {
        setConfigId(res?.res?._id)
        setConfigData(res?.res)
        setTags(res?.res?.steerCuratedList)
      })
      .finally(() => setLoading(false))
  }, [])

  const handleInputChange = (e) => {
    setInputValue(e.target.value)
  }

  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue])
    }
    setInputVisible(false)
    setInputValue('')
  }

  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag)
    setTags(newTags)
  }

  const showInput = () => {
    setInputVisible(true)
  }

  const forMap = (tag) => {
    const tagElem = (
      <Tag
        closable
        onClose={(e) => {
          e.preventDefault()
          handleClose(tag)
        }}
      >
        {tag}
      </Tag>
    )
    return (
      <span
        key={tag}
        style={{
          display: 'inline-block',
        }}
      >
        {tagElem}
      </span>
    )
  }

  const tagChild = tags.map(forMap)

  const openNotification = () => {
    notification.open({
      message: 'Updated Successfully',
    })
  }

  const onFinish = () => {
    form
      .validateFields()
      .then(async (values) => {
        let userData = StorageService.getData('user')
        let requestObj = {
          ...values,
          steerCuratedList: tags,
          updatedById: userData._id,
        }
        const response = await APIService.requestWithToken(
          `api/v1/organization/update-common-config/${configId}`,
          'put',
          requestObj
        )
        if (
          response?.res?.message.includes('Configuration updated successfully')
        ) {
          openNotification()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <div className='df  am'>
        <Title level={3} style={{ flex: 1 }}>
          {' '}
          Common Configuration
        </Title>
      </div>
      {!loading && (
        <Form
          form={form}
          initialValues={configData}
          name='control-hooks'
          layout='vertical'
          onFinish={onFinish}
        >
          <Row justify='space-between'>
            <Col xs={7} md={11}>
              <Form.Item name='steerCuratedList' label='Steer Curated List'>
                {!inputVisible && (
                  <Tag onClick={showInput} className='site-tag-plus'>
                    <PlusOutlined /> Add New Steer Curated List
                  </Tag>
                )}
                {inputVisible && (
                  <Input
                    ref={inputRef}
                    type='text'
                    size='medium'
                    placeholder='Steer Curated List'
                    maxLength={50}
                    // rules={[{ required: true, message: "Only Number allowed" }]}
                    style={{
                      width: '100%',
                    }}
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleInputConfirm}
                    onPressEnter={handleInputConfirm}
                  />
                )}
                <div style={{ marginTop: 16 }}>
                  <TweenOneGroup
                    enter={{
                      scale: 0.8,
                      opacity: 0,
                      type: 'from',
                      duration: 100,
                    }}
                    onEnd={(e) => {
                      if (e.type === 'appear' || e.type === 'enter') {
                        e.target.style = 'display: inline-block'
                      }
                    }}
                    leave={{
                      opacity: 0,
                      width: 0,
                      scale: 0,
                      duration: 200,
                    }}
                    appear={false}
                  >
                    {tagChild}
                  </TweenOneGroup>
                </div>
              </Form.Item>
            </Col>

            <Col xs={7} md={11} style={{ alignItems: 'end' }}>
              <Form.Item
                name='updatedBy'
                label={`Last updated by: ${configData?.updatedById?.name} `}
                className='updatedBy'
              >
                {/* {'updatedBY'} */}
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            wrapperCol={{
              offset: 11,
              span: 16,
            }}
          >
            <div>
              <Button type='primary' htmlType='submit'>
                Save
              </Button>
            </div>
          </Form.Item>
        </Form>
      )}
    </>
  )
}

export default CommonConfiguration

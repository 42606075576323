import React from "react";
import { Input, Row, Form, Col, Button, notification } from "antd";
import StorageService from "../../../services/storage";
import APIService from "../../../services/api";

function ConfigInsurance({ data, id }) {
    const [form] = Form.useForm();
    const openNotification = () => {
        notification.open({
            message: "Updated Successfully",
        });
    };
    const onSettingFinish = () => {
        form
            .validateFields()
            .then((values) => {
                let userData = StorageService.getData("user");
                let requestObj = {
                    ...values,
                    patientAcceptanceQuota: {
                        HMOs: values?.HMOs,
                        commercial: values?.commercial,
                        medicare: values?.medicare,
                        medicaid: values?.medicaid
                    },
                    userId: userData?._id,
                    organizationId: id,
                    organizationName: data?.name,
                    organizationType: data?.organizationType,
                };
                APIService.requestWithToken(
                    "api/v1/organization/addOrEditOrganization",
                    "post",
                    requestObj
                );
            })
            .finally(() => {
                openNotification();
            });
    };

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                name="control-hooks"
                initialValues={data?.patientAcceptanceQuota}
                onFinish={onSettingFinish}
            >
                <Row justify="space-between">
                    <Col xs={7} md={11}>
                        <Form.Item name="HMOs" label="HMOs">
                            <Input placeholder="HMOs Number" type="Number"/>
                        </Form.Item>
                    </Col>
                    <Col xs={7} md={11}>
                        <Form.Item name="commercial" label="commercial">
                            <Input placeholder="Commercial Number" type="Number"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="space-between">
                    <Col xs={7} md={11}>
                        <Form.Item name="medicare" label="medicare">
                            <Input placeholder="Medicare Number" type="Number"/>
                        </Form.Item>
                    </Col>
                    <Col xs={7} md={11}>
                        <Form.Item name="medicaid" label="medicaid">
                            <Input placeholder="Medicaid Number" type="Number"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    wrapperCol={{
                        offset: 11,
                        span: 16,
                    }}
                >
                    <div>
                        <Button type="primary" htmlType="submit">
                            Save
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </>
    );
}

export default ConfigInsurance;

import React, { useEffect, useState } from "react";
import {
    Table,
    Input,
    Space,
    Button,
    Typography,
    Select,
    Tabs,
    Spin,
    Modal
} from "antd";
import { ExclamationCircleOutlined, EditTwoTone, DeleteTwoTone } from "@ant-design/icons";
import APIService from "../../services/api";
import AddEdit from "./AddEdit";
import { masterCodes } from "../../constants/common";
const { Title } = Typography;
const { confirm } = Modal;
const { Search } = Input;
const { TabPane } = Tabs;

function SubMaster() {
    const scrollHeight = window.innerHeight - 337;
    const [activeTab, updateTabData] = useState(masterCodes[0].key);
    const [modalData, setModalData] = useState({
        visible: false,
        data: {},
        parent_code: activeTab,
        success: false,
    });

    const [fetching, setFetching] = React.useState(false);
    const [tableData, updateTableData] = useState({
        loading: false,
        dataSource: [],
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0,
        },
    });
    const [searchQuery, updateSearchQuery] = useState(undefined);
    const columns = [
        {
            title: "Title",
            dataIndex: "title",
        },
        {
            title: "Value",
            dataIndex: "value",
        },
        {
            title: "Actions",
            width: 100,
            align: 'center',
            render: (obj) => {
                return (
                    <Space>
                        <EditTwoTone
                            onClick={() =>
                                setModalData({
                                    ...modalData,
                                    parent_code: activeTab,
                                    visible: true,
                                    data: obj,
                                })
                            }
                        />
                        <DeleteTwoTone
                            onClick={() =>
                                confirm({
                                    title: 'Do you Want to delete these items?',
                                    icon: <ExclamationCircleOutlined />,
                                    content: '',
                                    onOk() {
                                        setFetching(true);
                                        APIService.requestWithToken(
                                            "api/v1/admin/delete-master/" + obj._id,
                                            "get"
                                        ).then(({ res }) => {
                                            if (res && res.code === "OK") {
                                                getData(tableData.pagination);
                                                setFetching(false);
                                            }
                                        });
                                    },
                                    onCancel() {
                                        console.log('Cancel');
                                    },
                                })
                            }
                        />
                    </Space>
                );
            },
        },
    ];

    useEffect(() => {
        console.log(activeTab);
        if (activeTab) {
            getData(tableData.pagination);
        }
    }, [activeTab, searchQuery]);


    useEffect(() => {
        if (modalData.success) {
            getData(tableData.pagination);
            setModalData({
                visible: false,
                data: {},
                success: false
            })
        }
    }, [modalData.success]);

    const getData = (pagination) => {
        updateTableData({
            ...tableData,
            pagination: {
                ...pagination,
            },
            loading: true,
            dataSource: [],
        });
        let query = { parent_code: activeTab };
        if (searchQuery) {
            query = { ...searchQuery };
        }

        APIService.requestWithToken("api/v1/admin/masters/list", "get", {
            pageNo: parseInt(pagination.current),
            limit: pagination.pageSize,
            query: query ? JSON.stringify(query) : null,
            sort: JSON.stringify({ createdAt: -1 }),
        }).then(({ res }) => {
            if (res && res.code === "OK") {
                updateTableData({
                    loading: false,
                    dataSource: res.data.list,
                    pagination: {
                        ...pagination,
                        total: res.data.count,
                    },
                });
            }
        });
    };

    return (
        <div>
            <div className="df jsb am">
                <Title level={3}>Sub Masters</Title>
                <Space>
                    <Space>
                        <Search
                            placeholder="Input search text"
                            onSearch={(searchStr) => {
                                updateSearchQuery({
                                    title: { $regex: searchStr, $options: "i" },
                                });
                            }}
                            style={{ width: 200 }}
                        />
                    </Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            setModalData({ ...modalData, parent_code: activeTab, visible: true })
                        }
                    >
                        {" "}
                        Add
                    </Button>
                </Space>
            </div>
            <Tabs defaultActiveKey={activeTab} tabPosition="left" style={{ height: 220 }} onChange={(value) => updateTabData(value)}>
                {masterCodes.map(master => (
                    <TabPane tab={master.value} key={master.key} >
                        <Table
                            {...tableData}
                            columns={columns}
                            onChange={getData}
                            scroll={{ y: scrollHeight }}
                        ></Table>
                    </TabPane>
                ))}
            </Tabs>

            {modalData.visible ? (
                <AddEdit
                    modalData={modalData}
                    setModalData={setModalData}
                ></AddEdit>
            ) : null}
        </div>
    );

}

export default SubMaster;

import React, { useEffect, useState } from "react";
import {
  Table,
  Input,
  Space,
  Typography,
  Select,
  Button,
  Modal,
  Row,
  Form,
  Col,
  Spin,
  // Checkbox,
  Divider,
  Radio
} from "antd";
import { useHistory } from "react-router-dom";
import APIService from "../../services/api";
import StorageService from "../../services/storage";
import {organizationStatusObj } from "../../constants/common";
// import { organizationType, timeZone } from "../../constants/common";
import { CheckCircleTwoTone, CloseCircleTwoTone, EditTwoTone } from "@ant-design/icons";
import './index.css'
import AddSteps from "./AddSteps";

const { Option } = Select;
const { Title } = Typography;
const { Search } = Input;
function OrganizationList() {
  const [form] = Form.useForm();
  const history = useHistory()
  const scrollHeight = window.innerHeight - 337;
  const [showModel, setShowModel] = useState({
    visible: false,
    success: false,
  });
  const [showSettingModel, setShowSettingModel] = useState({
    visible: false,
    success: false,
  });
  const [loading, setLoading] = useState(false);
  const [organizationName, setOrganizationName] = useState("");
  const [orgType, setOrgType] = useState("");
  const [facilityUid, setFacilityUid] = useState("")
  const [facilityCode, setFacilityCode] = useState("")
  // const [fetching, setFetching] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [initialSettingValues, setInitialSettingValues] = useState({});
  const [isPMPOnly, setIsPMPOnly] = useState(false)
  const [abbreviationName, setAbbreviationName] = useState("")
  const [isTeleMedicineServices, setIsTeleMedicineServices] = useState(true)
  const [searchQuery, updateSearchQuery] = useState(undefined);
  const [street, setStreet] = useState("")
  const [city, SetCity] = useState("")
  const [state, setState] = useState("")
  const [zipCode, setZipCode] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [faxNumber, setFaxNumber] = useState("")
  const [Zone, setZone] = useState("");
  const [organizationStatus,setOrganizationStatus] = useState("active")
  const [tableData, updateTableData] = useState({
    loading: false,
    dataSource: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name"
    },
    {
      title: "Doctors",
      dataIndex: "doctorIds",
      render: (text) => {
        return text.length;
      }
    },
    {
      title: "Time Zone",
      dataIndex: "timeZone",

    },
    {
      title: "Address",
      render: (record) => {
        return record.street + ', ' + record.city + ', ' + record.state + ', ' + record.zipCode;
      }
    },
    {
      title: "Active",
      align: "center",
      width: 100,
      render: (obj) => {
        let deactivated = obj.deactivated;
        let orgId = obj._id
        return obj.deactivated ? (
          <CloseCircleTwoTone onClick={() => updateActive(deactivated,orgId)} twoToneColor="#FF0000" />
          ) : (
            <CheckCircleTwoTone onClick={() => updateActive(deactivated,orgId)} twoToneColor="#52c41a" />
        );
      },
    },
    {
      title: "Actions",
      width: 100,
      align: 'center',
      render: (obj) => {
        return (
          <Space>
            <EditTwoTone
              onClick={() => {
                history.push(`/app/${obj._id}/settings`)
                // setShowModel({
                //   visible: true,
                // })
                // setInitialValues({
                //   organizationName: obj.name,
                //   organizationType: obj.organizationType,
                //   organizationId: obj._id,
                //   abbreviationName: obj.abbreviationName,
                //   facilityUid: obj.facilityUid,
                //   facilityCode: obj.facilityCode,
                //   street: obj.street,
                //   city: obj.city,
                //   state: obj.state,
                //   zipCode: obj.zipCode,
                //   phoneNumber: obj.phoneNumber,
                //   faxNumber: obj.faxNumber,
                //   timeZone: obj.timeZone
                // })
                // setIsPMPOnly(obj.PMPOnly);
                // setIsTeleMedicineServices(obj.teleMedicineServices)
              }
              }
            />
            {/* <SettingTwoTone
              onClick={() => {
                setShowSettingModel({
                  visible: true,
                })
                setInitialSettingValues({
                  organizationName: obj.name,
                  organizationType: obj.organizationType,
                  organizationId: obj._id,
                  abbreviationName: obj.abbreviationName,
                  facilityUid: obj.facilityUid,
                  facilityCode: obj.facilityCode,
                  gcnRequestInBasket: obj.epicConfig ? obj.epicConfig.gcnRequestInBasket : '',
                  gcnRequestEmail: obj.epicConfig ? obj.epicConfig.gcnRequestEmail : '',
                  referralRequestInBasket: obj.epicConfig ? obj.epicConfig.referralRequestInBasket : '',
                  pxIncomingInBasket: obj.epicConfig ? obj.epicConfig.pxIncomingInBasket : '',
                  pxIncomingEmail: obj.epicConfig ? obj.epicConfig.pxIncomingEmail : '',
                  mktgIncomingInBasket: obj.epicConfig ? obj.epicConfig.mktgIncomingInBasket : '',
                  mktgIncomingEmail: obj.epicConfig ? obj.epicConfig.mktgIncomingEmail : '',
                  ptIncomingInBasket: obj.epicConfig ? obj.epicConfig.ptIncomingInBasket : '',
                  way2SmsChatIncomingInBasket: obj.epicConfig ? obj.epicConfig.way2SmsChatIncomingInBasket : '',
                  carePlusIncomingInBasket: obj.epicConfig ? obj.epicConfig.carePlusIncomingInBasket : '',
                  locationUrl: obj.hospitalChatbot ? obj.hospitalChatbot.locationUrl : '',
                  doctorUrl: obj.hospitalChatbot ? obj.hospitalChatbot.doctorUrl : '',
                  patientForm: obj.hospitalChatbot ? obj.hospitalChatbot.patientForm : '',
                  referralUrl: obj.hospitalChatbot ? obj.hospitalChatbot.referralUrl : '',
                  patientServices: obj.hospitalChatbot ? obj.hospitalChatbot.patientServices : '',
                  newPatient: obj.consultTimeConfig?.newPatient ? obj.consultTimeConfig?.newPatient : '',
                  existsPatient: obj.consultTimeConfig?.existsPatient ? obj.consultTimeConfig?.existsPatient : '',
                })
                setIsPMPOnly(obj.PMPOnly);
                setIsTeleMedicineServices(obj.teleMedicineServices)
              }
              }
            /> */}
          </Space>
        );
      },
    },
  ];
    const updateActive = (value,organizationId) => {
    let reqObj = { deactivated: !value }
    APIService.requestWithToken("api/v1/organization/" + organizationId, "put", reqObj).then(({ res }) => {
      if (res && res.code === "OK") {
        getData(tableData.pagination);
      }
    });

  }

  useEffect(() => {
    getData(tableData.pagination);
  }, [searchQuery,organizationStatus]);

  useEffect(() => {
    if (showModel.success) {
      getData(tableData.pagination);
      setShowModel({
        visible: false,
        data: {},
        success: false
      })
    }
  }, [showModel.success]);

  const getData = (pagination) => {
    updateTableData({
      ...tableData,
      pagination: {
        ...pagination,
      },
      loading: true,
      dataSource: [],
    });
    let query = {};
    if (searchQuery) {
      query = { ...searchQuery };
    }
    if(organizationStatus === "active"){
      query = {...query, ...query["$and"]={$or:[{deactivated:false},{deactivated:{$exists:false}}]}}
    }
    else if (organizationStatus === "deactivate"){
      query =  {...query, deactivated:true}
    }



    APIService.requestWithToken("api/v1/admin/org/list", "get", {
      pageNo: parseInt(pagination.current),
      limit: pagination.pageSize,
      query: query ? JSON.stringify(query) : null,
      sort: JSON.stringify({ createdAt: -1 }),
    }).then(({ res }) => {
      if (res && res.code === "OK") {
        updateTableData({
          loading: false,
          dataSource: res.data.list,
          pagination: {
            ...pagination,
            total: res.data.count,
          },
        });
      }
    });
  };
  const onFinish = () => {
    setLoading(true);
    let userData = StorageService.getData('user')
    let requestObj = {
      organizationName: organizationName || initialValues.organizationName,
      abbreviationName: abbreviationName || initialValues.abbreviationName,
      organizationType: orgType || initialValues.organizationType,
      facilityUid: facilityUid || initialValues.facilityUid,
      facilityCode: facilityCode || initialValues.facilityCode,
      userId: userData._id,
      organizationId: initialValues.organizationId,
      PMPOnly: isPMPOnly,
      teleMedicineServices: isTeleMedicineServices,
      street: street || initialValues.street,
      city: city || initialValues.city,
      state: state || initialValues.state,
      zipCode: zipCode || initialValues.zipCode,
      phoneNumber: phoneNumber || initialValues.phoneNumber,
      faxNumber: faxNumber || initialValues.faxNumber,
      timeZone: Zone || initialValues.timeZone,
    }
    APIService.requestWithToken("api/v1/organization/addOrEditOrganization", "post", requestObj).then(
      ({ res }) => {
        setLoading(false);
        if (res && res.code === "OK") {
          setShowModel({ visible: false, success: true });
        }
        else {
          setLoading(false);
        }
      }
    );
  };
  const handleCancel = () => {
    setShowModel({ visible: false });
  };
  const handlePMPOny = (e) => {
    setIsPMPOnly(e.target.checked);
  }

  const onSettingFinish = () => {
    form
      .validateFields()
      .then((values) => {
        let userData = StorageService.getData('user')
        let requestObj = {
          epicConfig: values,
          // hospitalChatbot: values,
          // consultTimeConfig: values,
          hospitalChatbot: {
            locationUrl: values.locationUrl,
            doctorUrl: values.doctorUrl,
            patientForm: values.patientForm,
            referralUrl: values.referralUrl,
            patientServices: values.patientServices,
            billPay: values.billPay
          },
          consultTimeConfig: {
            newPatient: values.newPatient || initialSettingValues.newPatient,
            existsPatient: values.existsPatient || initialSettingValues.existsPatient,
          },
          userId: userData._id,
          PMPOnly: isPMPOnly,
          abbreviationName: abbreviationName || initialSettingValues.abbreviationName,
          facilityCode: facilityCode || initialSettingValues.facilityCode,
          facilityUid: facilityUid || initialSettingValues.facilityUid,
          organizationId: initialSettingValues.organizationId,
          organizationName: organizationName || initialSettingValues.organizationName,
          organizationType: initialSettingValues.organizationType,
          teleMedicineServices: initialSettingValues.isTeleMedicineServices,
        }
        APIService.requestWithToken("api/v1/organization/addOrEditOrganization", "post", requestObj).then(
          ({ res }) => {
            setLoading(false);
            if (res && res.code === "OK") {
              setShowSettingModel({ visible: false, success: true });
            }
            else {
              setLoading(false);
            }
          }
        )
      })
  };

  const handleSettingCancel = () => {
    setShowSettingModel({ visible: false });
  };

  return (
    <div>
      {showModel.visible && !loading &&
        <AddSteps
          // initialValues={initialValues}
          // onFinish={onFinish}
          // setOrganizationName={setOrganizationName}
          setAbbreviationName={setAbbreviationName}
          setStreet={setStreet}
          SetCity={SetCity}
          setState={setState}
          setZipCode={setZipCode}
          setPhoneNumber={setPhoneNumber}
          setFaxNumber={setFaxNumber}
          setZone={setZone}
          setFacilityUid={setFacilityUid}
          setOrgType={setOrgType}
          setFacilityCode={setFacilityCode}
          isPMPOnly={isPMPOnly}
          handlePMPOny={handlePMPOny}
          isTeleMedicineServices={isTeleMedicineServices}
          setIsTeleMedicineServices={setIsTeleMedicineServices}
        />
      }

      {/* {showModel.visible ? (
        <Modal
          width={800}
          title={initialValues.organizationId ? "Edit Organization" : "Add Organization"}
          visible={showModel.visible}
          okText="Submit"
          cancelText="Cancel"
          onOk={onFinish}
          onCancel={handleCancel}
          className={"edit-modal"}
        >
          {!loading ? (

            <Form
              // form={userform}
              initialValues={initialValues}
              name="control-hooks"
              layout="vertical"
              onFinish={onFinish}
            >

              <Form.Item
                name="organizationName"
                label="Organization Name"
                rules={[{ required: true }]}
                onChange={(e) => setOrganizationName(e.target.value)}
              >
                <Input
                  placeholder="Organization Name"
                />
              </Form.Item>
              <Form.Item
                name="abbreviationName"
                label="Abbreviation Name"
                rules={[{ required: true }]}
                onChange={(e) => setAbbreviationName(e.target.value)}
              >
                <Input
                  placeholder="Abbreviation Name"
                />
              </Form.Item>

              <Row justify="space-between">
                <Col xs={7} md={11}>
                  <Form.Item
                    name="street"
                    label="Street Address"
                    onChange={(e) => setStreet(e.target.value)}
                  >
                    <Input
                      placeholder="Street Address"
                    />
                  </Form.Item>
                </Col>

                <Col xs={7} md={11}>
                  <Form.Item
                    name="city"
                    label="City"
                    onChange={(e) => SetCity(e.target.value)}
                  >
                    <Input
                      placeholder="City"
                    /></Form.Item>
                </Col>
              </Row>

              <Row justify="space-between">
                <Col xs={7} md={11}>
                  <Form.Item
                    name="state"
                    label="State"
                    onChange={(e) => setState(e.target.value)}
                  >
                    <Input
                      placeholder="State"
                    />
                  </Form.Item>
                </Col>

                <Col xs={7} md={11}>
                  <Form.Item
                    name="zipCode"
                    label="Zip Code"
                    onChange={(e) => setZipCode(e.target.value)}
                  >
                    <Input
                      placeholder="Zip Code"
                    /></Form.Item>
                </Col>
              </Row>

              <Row justify="space-between">
                <Col xs={7} md={11}>
                  <Form.Item
                    name="phoneNumber"
                    label="Phone Number"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  >
                    <Input
                      placeholder="Phone Number"
                    />
                  </Form.Item>
                </Col>

                <Col xs={7} md={11}>
                  <Form.Item
                    name="faxNumber"
                    label="Fax"
                    onChange={(e) => setFaxNumber(e.target.value)}
                  >
                    <Input
                      placeholder="Fax"
                    /></Form.Item>
                </Col>
              </Row>
              <Col xs={7} md={11}>
                <Form.Item
                  name="timeZone"
                  label="TimeZone"
                // rules={[{ required: true, message: "Organization type is required." }]}
                >
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Select a TimeZone"
                    optionFilterProp="children"
                    onChange={(e) => setZone(e)}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {Object.entries(timeZone).map((group) => (
                      <Option key={group[1]?.timezone} value={group[1]?.timezone}>{`${group[1].timezone
                        // .replace(/([A-Z])/g, " $1")
                        .trim()
                        .toUpperCase()}`}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Row justify="space-between">
                <Col xs={7} md={11}>
                  <Form.Item
                    name="facilityUid"
                    label="Facility Uid"
                    onChange={(e) => setFacilityUid(e.target.value)}
                  >
                    <Input
                      placeholder="Facility Uid"
                    />
                  </Form.Item>
                </Col>

                <Col xs={7} md={11}>
                  <Form.Item
                    name="CarePlus incoming InBasket"
                    label="CarePlus incoming InBasket"
                    onChange={(e) => setFacilityCode(e.target.value)}
                  >
                    <Input
                      placeholder="CarePlus incoming InBasket"
                    /></Form.Item>
                </Col>
              </Row>

              <Col xs={7} md={11}>
                <Form.Item
                  name="organizationType"
                  label="Organization Type"
                  rules={[{ required: true, message: "Organization type is required." }]}
                >

                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Select a Type"
                    optionFilterProp="children"
                    onChange={(e) => setOrgType(e)}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {Object.entries(organizationType).map((group) => (
                      <Option key={group[0]} value={group[1]}>{`${group[0]
                        .replace(/([A-Z])/g, " $1")
                        .trim()
                        .toUpperCase()}`}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={7} md={9}>
                <Form.Item>
                  <Checkbox checked={isPMPOnly} onChange={handlePMPOny}>PMPOnly</Checkbox>
                  <Checkbox checked={isTeleMedicineServices} onChange={(e) => setIsTeleMedicineServices(e.target.checked)}>TeleMedicine Services</Checkbox>

                </Form.Item>
              </Col>
            </Form>
          ) : (
            <Spin />
          )}
        </Modal>
      ) : null
      } */}

      {!!showSettingModel.visible && (
        <Modal
          visible={showSettingModel.visible}
          title={"Settings"}
          okText="Submit"
          cancelText="Cancel"
          width={800}
          onOk={onSettingFinish}
          onCancel={handleSettingCancel}
          className={"setting-modal"}
        >
          {!loading ? (
            <Form
              form={form}
              layout="vertical"
              name="control-hooks"
              initialValues={initialSettingValues}
            >
              <Divider>Epic</Divider>
              <Row justify="space-between">
                <Col xs={7} md={11}>
                  <Form.Item
                    name="gcnRequestInBasket"
                    label="GetCareNow Request InBasket"
                    rules={[
                      {
                        min: 3,
                        max: 25,
                        message: "between 3 and 25 characters required"
                      },
                    ]}
                  >
                    <Input
                      placeholder="GetCareNow Request InBasket"
                    />
                  </Form.Item>
                </Col>
                <Col xs={7} md={11}>
                  <Form.Item
                    name="gcnRequestEmail"
                    label="GetCareNow Request Email"
                    rules={[
                      {
                        min: 3,
                        max: 28,
                        message: "between 3 and 28 characters required"
                      },
                    ]}
                  >
                    <Input
                      placeholder="GetCareNow Request Email" maxLength={28}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="space-between">
                <Col xs={7} md={11}>
                  <Form.Item
                    name="pxIncomingInBasket"
                    label="Px Incoming InBasket"
                    rules={[
                      {
                        min: 3,
                        max: 25,
                        message: "between 3 and 25 characters required"
                      },
                    ]}
                  >
                    <Input
                      placeholder="Px Incoming InBasket" maxLength={6}
                    />
                  </Form.Item>
                </Col>
                <Col xs={7} md={11}>
                  <Form.Item
                    name="pxIncomingEmail"
                    label="Px Incoming Email"
                    rules={[
                      {
                        min: 3,
                        max: 28,
                        message: "between 3 and 28 characters required"
                      },
                    ]}
                  >
                    <Input
                      placeholder="Px Incoming Email" maxLength={28}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="space-between">
                <Col xs={7} md={11}>
                  <Form.Item
                    name="mktgIncomingInBasket"
                    label="Mktg Incoming InBasket"
                    rules={[
                      {
                        min: 3,
                        max: 25,
                        message: "between 3 and 25 characters required"
                      },
                    ]}
                  >
                    <Input
                      placeholder="Mktg Incoming InBasket" maxLength={6}
                    />
                  </Form.Item>
                </Col>

                <Col xs={7} md={11}>
                  <Form.Item
                    name="mktgIncomingEmail"
                    label="Mktg Incoming Email"
                    rules={[
                      {
                        min: 3,
                        max: 28,
                        message: "between 3 and 28 characters required"
                      },
                    ]}
                  >
                    <Input
                      placeholder="Mktg Incoming Email" maxLength={28}
                    /></Form.Item>
                </Col>
              </Row>

              <Row justify="space-between">
                <Col xs={7} md={11}>
                  <Form.Item
                    name="ptIncomingInBasket"
                    label="Pt Outreach Incoming InBasket"
                    rules={[
                      {
                        min: 3,
                        max: 25,
                        message: "between 3 and 25 characters required"
                      },
                    ]}
                  >
                    <Input
                      placeholder="Pt Outreach Incoming InBasket" maxLength={6}
                    />
                  </Form.Item>
                </Col>

                <Col xs={7} md={11}>
                  <Form.Item
                    name="way2SmsChatIncomingInBasket"
                    label="2Way SMS chat Incoming InBasket"
                    rules={[
                      {
                        min: 3,
                        max: 25,
                        message: "between 3 and 25 characters required"
                      },
                    ]}
                  >
                    <Input
                      placeholder="2Way SMS chat Incoming InBasket" maxLength={28}
                    /></Form.Item>
                </Col>
              </Row>

              <Row justify="space-between">
                <Col xs={7} md={11}>
                  <Form.Item
                    name="referralRequestInBasket"
                    label="Referral Request InBasket"
                    rules={[
                      {
                        min: 3,
                        max: 25,
                        message: "between 3 and 25 characters required"
                      },
                    ]}
                  >
                    <Input
                      placeholder="Referral Request InBasket" maxLength={6}
                    />
                  </Form.Item>
                </Col>

                <Col xs={7} md={11}>
                  <Form.Item
                    name="carePlusIncomingInBasket"
                    label="CarePlus Incoming InBasket"
                    rules={[
                      {
                        min: 3,
                        max: 25,
                        message: "between 3 and 25 characters required"
                      },
                    ]}
                  >
                    <Input placeholder="CarePlus Incoming InBasket" />
                  </Form.Item>
                </Col>
              </Row>

              <Divider>Hospital Chat Bot</Divider>
              <Row justify="space-between">
                <Col xs={7} md={11}>
                  <Form.Item
                    name="locationUrl"
                    label="Find a Location URL"
                  >
                    <Input
                      placeholder="Find a Location URL"
                    />
                  </Form.Item>
                </Col>

                <Col xs={7} md={11}>
                  <Form.Item
                    name="doctorUrl"
                    label="Find a Doctor URL"
                  >
                    <Input placeholder="Find a Doctor URL" />
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="space-between">
                <Col xs={7} md={11}>
                  <Form.Item
                    name="patientForm"
                    label="Patient Forms"
                  >
                    <Input
                      placeholder="Patient Forms"
                    />
                  </Form.Item>
                </Col>

                <Col xs={7} md={11}>
                  <Form.Item
                    name="referralUrl"
                    label="Submit a Referral URL"
                  >
                    <Input placeholder="Submit a Referral URL" />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="space-between">
                <Col xs={7} md={11}>
                  <Form.Item
                    name="patientServices"
                    label="Patient Services"
                  >
                    <Input
                      placeholder="Patient Services"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="space-between">
                <Col xs={7} md={11}>
                  <Form.Item
                    name="billPay"
                    label="Bill Pay"
                  >
                    <Input
                      placeholder="Bill Pay"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Divider>New Consult Time Slots Difference</Divider>
              <Row justify="space-between">
                <Col xs={7} md={11}>
                  <Form.Item
                    name="newPatient"
                    label="New Patient"
                  >
                    <Radio.Group >
                      <Radio.Button value={initialSettingValues?.newPatient === "15" ? initialSettingValues?.newPatient : 15}>15 Min</Radio.Button>
                      <Radio.Button value={initialSettingValues?.newPatient === "30" ? initialSettingValues?.newPatient : 30}>30 Min</Radio.Button>
                      <Radio.Button value={initialSettingValues?.newPatient === "45" ? initialSettingValues?.newPatient : 45}>45 Min</Radio.Button>
                      <Radio.Button value={initialSettingValues?.newPatient === "60" ? initialSettingValues?.newPatient : 60}>60 Min</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Divider type="vertical" />
                <Col xs={7} md={11}>
                  <Form.Item
                    name="existsPatient"
                    label="Exists Patient"
                  >
                    <Radio.Group >
                      <Radio.Button value={initialSettingValues?.existsPatient === "15" ? initialSettingValues?.existsPatient : 15}>15 Min</Radio.Button>
                      <Radio.Button value={initialSettingValues?.existsPatient === "30" ? initialSettingValues?.existsPatient : 30}>30 Min</Radio.Button>
                      <Radio.Button value={initialSettingValues?.existsPatient === "45" ? initialSettingValues?.existsPatient : 45}>45 Min</Radio.Button>
                      <Radio.Button value={initialSettingValues?.existsPatient === "60" ? initialSettingValues?.existsPatient : 60}>60 Min</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>

            </Form>
          ) : (
            <Spin />
          )}
        </Modal>
      )}

      <div className="df  am">
        <Title level={3} style={{ flex: 1 }}> Organizations ({tableData.pagination.total})</Title>
        <Space>
          <Space>
          <Select
                  defaultValue={organizationStatus}
                  showSearch
                  style={{ width: 200 }}
                  optionFilterProp="children"
                  onChange={(e)=> {setOrganizationStatus(e)}}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {Object.entries(organizationStatusObj).map((group) => (
                    <Option key={group[0]} value={group[1]}>{`${group[0]
                      .replace(/([A-Z])/g, " $1")
                      .trim()
                      .toUpperCase()}`}</Option>
                  ))}
                </Select>
            <Search
              placeholder="Search by organization name"
              onSearch={(searchStr) => {
                updateSearchQuery({
                  name: { $regex: searchStr, $options: "i" },
                });
              }}
              style={{ width: 200 }}
            />
          </Space>

        </Space>
        <Button
          type="primary"
          onClick={() => {
            history.push(`/app/organizations/add`)
          }
            // onClick={() => {
            //   setShowModel({ ...showModel, visible: true })
            //   setInitialValues({})
            // }
          }
        >
          {" "}
          Add
        </Button>
      </div>

      <Table
        {...tableData}
        columns={columns}
        onChange={getData}
        scroll={{ y: scrollHeight }}
      ></Table>

    </div >
  );

}

export default OrganizationList;

import React from 'react'
import { useHistory } from 'react-router-dom';
import { Row, Form, Col, Divider, Radio, Button, notification } from "antd";

import APIService from "../../../services/api";

function NewConsultTimeSlotsDifferenceStep({ prev, stepData, step2Data, step3Data, step4Data, setStep4Data, tags }) {
  const [form] = Form.useForm();
  const history = useHistory()

  const openNotification = () => {
    notification.open({
      message: 'Organization Created Successfully',
    });
  };

  const onFinish = (values) => {
    setStep4Data(values)
    const NewStepData = {
      sourceOrganizationIds: tags,
      ...stepData,
    }
    const data = {
      ...NewStepData,
      ...step2Data,
      ...step3Data,
      ...values
    }
    APIService.requestWithToken("api/v1/organization/addOrEditOrganization", "post", data)
      .then((res) => {
        history.push(`/app/organizations`)
      }).finally(() => {
        openNotification()
      })
  }

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        name="control-hooks"
        onFinish={onFinish}
        initialValues={step4Data}
      >
        <Row justify="space-between">
          <Col xs={7} md={11}>
            <Form.Item
              name="newPatient"
              label="New Patient"
            >
              <Radio.Group>
                <Radio.Button value={15}>15 Min</Radio.Button>
                <Radio.Button value={30}>30 Min</Radio.Button>
                <Radio.Button value={45}>45 Min</Radio.Button>
                <Radio.Button value={60}>60 Min</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Divider type="vertical" />
          <Col xs={7} md={11}>
            <Form.Item
              name="existsPatient"
              label="Exists Patient"
            >
              <Radio.Group>
                <Radio.Button value={15}>15 Min</Radio.Button>
                <Radio.Button value={30}>30 Min</Radio.Button>
                <Radio.Button value={45}>45 Min</Radio.Button>
                <Radio.Button value={60}>60 Min</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          wrapperCol={{
            offset: 10,
            span: 16,
          }}
        >
          <div className='done-btn'>
            <Button
              style={{ margin: '0 8px' }}
              onClick={() => prev()}
            >
              Previous
            </Button>
            <Button type="primary" htmlType="submit">
              Done
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  )
}

export default NewConsultTimeSlotsDifferenceStep

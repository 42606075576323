import React from 'react'
import { Input, Button, Row, Form, Col, notification, Tabs } from 'antd'
import StorageService from '../../services/storage'
import APIService from '../../services/api'

const InBasket = ({ data, id }) => {
  const [form] = Form.useForm()

  const openNotification = () => {
    notification.open({
      message: 'Updated Successfully',
    })
  }

  const onSettingFinish = () => {
    form
      .validateFields()
      .then((values) => {
        let userData = StorageService.getData('user')
        let requestObj = {
          epicConfig: values,
          userId: userData?._id,
          organizationId: id,
          organizationName: data?.name,
          organizationType: data?.organizationType,
        }
        APIService.requestWithToken(
          'api/v1/organization/addOrEditOrganization',
          'post',
          requestObj
        )
      })
      .finally(() => {
        openNotification()
      })
  }

  return (
    <Form
      form={form}
      layout='vertical'
      name='control-hooks'
      initialValues={data.epicConfig}
      onFinish={onSettingFinish}
    >
      <Row justify='space-between'>
        <Col xs={7} md={11}>
          <Form.Item
            name='gcnRequestInBasket'
            label='GetCareNow Request InBasket'
            rules={[
              {
                min: 3,
                max: 50,
                message: 'between 3 and 50 characters required',
              },
            ]}
          >
            <Input placeholder='GetCareNow Request InBasket' />
          </Form.Item>
        </Col>
        <Col xs={7} md={11}>
          <Form.Item
            name='gcnRequestEmail'
            label='GetCareNow Request Email'
            rules={[
              {
                min: 3,
                max: 50,
                message: 'between 3 and 50 characters required',
              },
            ]}
          >
            <Input placeholder='GetCareNow Request Email' maxLength={50} />
          </Form.Item>
        </Col>
        <Col xs={7} md={11}>
          <Form.Item
            name='getCareNowMessageType'
            label='GetCareNow Message Type'
            rules={[
              {
                min: 3,
                max: 50,
                message: 'between 3 and 50 characters required',
              },
            ]}
          >
            <Input placeholder='getCareNow Message Type' maxLength={50} />
          </Form.Item>
        </Col>

        <Col xs={7} md={11}>
          <Form.Item
            name='pxIncomingInBasket'
            label='Px Incoming InBasket'
            rules={[
              {
                min: 3,
                max: 50,
                message: 'between 3 and 50 characters required',
              },
            ]}
          >
            <Input placeholder='Px Incoming InBasket' maxLength={50} />
          </Form.Item>
        </Col>
        <Col xs={7} md={11}>
          <Form.Item
            name='pxIncomingEmail'
            label='Px Incoming Email'
            rules={[
              {
                min: 3,
                max: 50,
                message: 'between 3 and 50 characters required',
              },
            ]}
          >
            <Input placeholder='Px Incoming Email' maxLength={50} />
          </Form.Item>
        </Col>

        <Col xs={7} md={11}>
          <Form.Item
            name='mktgIncomingInBasket'
            label='Mktg Incoming InBasket'
            rules={[
              {
                min: 3,
                max: 50,
                message: 'between 3 and 50 characters required',
              },
            ]}
          >
            <Input placeholder='Mktg Incoming InBasket' maxLength={50} />
          </Form.Item>
        </Col>

        <Col xs={7} md={11}>
          <Form.Item
            name='mktgIncomingEmail'
            label='Mktg Incoming Email'
            rules={[
              {
                min: 3,
                max: 50,
                message: 'between 3 and 50 characters required',
              },
            ]}
          >
            <Input placeholder='Mktg Incoming Email' maxLength={50} />
          </Form.Item>
        </Col>

        <Col xs={7} md={11}>
          <Form.Item
            name='ptIncomingInBasket'
            label='Pt Outreach Incoming InBasket'
            rules={[
              {
                min: 3,
                max: 50,
                message: 'between 3 and 50 characters required',
              },
            ]}
          >
            <Input placeholder='Pt Outreach Incoming InBasket' maxLength={50} />
          </Form.Item>
        </Col>

        <Col xs={7} md={11}>
          <Form.Item
            name='way2SmsChatIncomingInBasket'
            label='2Way SMS chat Incoming InBasket'
            rules={[
              {
                min: 3,
                max: 50,
                message: 'between 3 and 50 characters required',
              },
            ]}
          >
            <Input
              placeholder='2Way SMS chat Incoming InBasket'
              maxLength={50}
            />
          </Form.Item>
        </Col>

        <Col xs={7} md={11}>
          <Form.Item
            name='referralRequestInBasket'
            label='Referral Request InBasket'
            rules={[
              {
                min: 3,
                max: 50,
                message: 'between 3 and 50 characters required',
              },
            ]}
          >
            <Input placeholder='Referral Request InBasket' maxLength={50} />
          </Form.Item>
        </Col>

        <Col xs={7} md={11}>
          <Form.Item
            name='referralRequestMessageType'
            label='Referral Request Message Type'
            rules={[
              {
                min: 3,
                max: 50,
                message: 'between 3 and 50 characters required',
              },
            ]}
          >
            <Input placeholder='Referral Request Message Type' maxLength={50} />
          </Form.Item>
        </Col>

        <Col xs={7} md={11}>
          <Form.Item
            name='carePlusIncomingInBasket'
            label='CarePlus Incoming InBasket'
            rules={[
              {
                min: 3,
                max: 50,
                message: 'between 3 and 50 characters required',
              },
            ]}
          >
            <Input placeholder='CarePlus Incoming InBasket' />
          </Form.Item>
        </Col>
        <Col xs={7} md={11}>
          <Form.Item
            name='eRegistrationEmail'
            label='eRegistration Email'
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                message: 'Please input your E-mail!',
              },
            ]}
          >
            <Input placeholder='eRegistration Email' maxLength={50} />
          </Form.Item>
        </Col>

        <Col xs={7} md={11}>
          <Form.Item
            name='eRegistrationInBasket'
            label='eRegistration In Basket'
            rules={[
              {
                min: 3,
                max: 50,
                message: 'between 3 and 50 characters required',
              },
            ]}
          >
            <Input placeholder='eRegistration In Basket' maxLength={50} />
          </Form.Item>
        </Col>
        <Col xs={7} md={11}>
          <Form.Item
            name='consultRequestEmail'
            label='Consult Request Email'
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                message: 'Please input your E-mail!',
              },
            ]}
          >
            <Input placeholder='Consult Request Email' maxLength={50} />
          </Form.Item>
        </Col>

        <Col xs={7} md={11}>
          <Form.Item
            name='consultRequestInBasket'
            label='Consult Request In Basket'
            rules={[
              {
                min: 3,
                max: 50,
                message: 'between 3 and 50 characters required',
              },
            ]}
          >
            <Input placeholder='Consult Request In Basket' maxLength={50} />
          </Form.Item>
        </Col>
        <Col xs={7} md={11}>
          <Form.Item
            name='outpatientMessageType'
            label='Outpatient Message Type'
            rules={[
              {
                min: 3,
                max: 50,
                message: 'between 3 and 50 characters required',
              },
            ]}
          >
            <Input placeholder='outpatient Message Type' maxLength={50} />
          </Form.Item>
        </Col>
        <Col xs={7} md={11}>
          <Form.Item
            name='departmentId'
            label='Department ID'
            rules={[
              {
                min: 3,
                max: 50,
                message: 'between 3 and 50 characters required',
              },
            ]}
          >
            <Input placeholder='Department ID' maxLength={50} />
          </Form.Item>
        </Col>

        <Col xs={7} md={11}>
          <Form.Item
            name='departmentIdType'
            label='Department ID Type'
            rules={[
              {
                min: 3,
                max: 50,
                message: 'between 3 and 50 characters required',
              },
            ]}
          >
            <Input placeholder='Department ID Type' maxLength={50} />
          </Form.Item>
        </Col>
        <Col xs={7} md={11}>
          <Form.Item
            name='urgentCareInBasket'
            label='Urgent Care In Basket'
            rules={[
              {
                min: 3,
                max: 50,
                message: 'between 3 and 50 characters required',
              },
            ]}
          >
            <Input placeholder='UrgentCare In Basket' />
          </Form.Item>
        </Col>
        <Col xs={7} md={11}>
          <Form.Item
            name='urgentCareMessageType'
            label='Urgent Care Message Type'
            rules={[
              {
                min: 3,
                max: 50,
                message: 'between 3 and 50 characters required',
              },
            ]}
          >
            <Input placeholder='UrgentCare Message Type' maxLength={50} />
          </Form.Item>
        </Col>
        <Col xs={7} md={11}>
          <Form.Item
            name='labResultRequestInBasket'
            label='Lab Result Request In Basket'
            rules={[
              {
                min: 3,
                max: 50,
                message: 'between 3 and 50 characters required',
              },
            ]}
          >
            <Input placeholder='Lab Result Request In Basket' maxLength={50} />
          </Form.Item>
        </Col>
        <Col xs={7} md={11}>
          <Form.Item
            name='labResultRequestMessageType'
            label='Lab Result Request Message Type'
            rules={[
              {
                min: 3,
                max: 50,
                message: 'between 3 and 50 characters required',
              },
            ]}
          >
            <Input
              placeholder='Lab Result Request Message Type'
              maxLength={50}
            />
          </Form.Item>
        </Col>
        <Col xs={7} md={11}>
          <Form.Item
            name='medicationRefillRequestMessageType'
            label='Medication Refill Request Message Type'
            rules={[
              {
                min: 3,
                max: 50,
                message: 'between 3 and 50 characters required',
              },
            ]}
          >
            <Input
              placeholder='Medication Refill Request Message Type'
              maxLength={50}
            />
          </Form.Item>
        </Col>
        <Col xs={7} md={11}>
          <Form.Item
            name='medicationRefillRequestInBasket'
            label='Medication Refill Request In Basket'
            rules={[
              {
                min: 3,
                max: 50,
                message: 'between 3 and 50 characters required',
              },
            ]}
          >
            <Input
              placeholder='Medication Refill Request In Basket'
              maxLength={50}
            />
          </Form.Item>
        </Col>
        <Col xs={7} md={11}>
          <Form.Item
            name='otherRequestMessageType'
            label='Other Request Message Type'
            rules={[
              {
                min: 3,
                max: 50,
                message: 'between 3 and 50 characters required',
              },
            ]}
          >
            <Input placeholder='Other Request Message Type' maxLength={50} />
          </Form.Item>
        </Col>
        <Col xs={7} md={11}>
          <Form.Item
            name='otherRequestInBasket'
            label='Other Request In Basket'
            rules={[
              {
                min: 3,
                max: 50,
                message: 'between 3 and 50 characters required',
              },
            ]}
          >
            <Input placeholder='Other Request In Basket' maxLength={50} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        wrapperCol={{
          offset: 11,
          span: 16,
        }}
      >
        <div>
          <Button type='primary' htmlType='submit'>
            Save
          </Button>
        </div>
      </Form.Item>
    </Form>
  )
}

export default InBasket

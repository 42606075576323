import React, { useState, useEffect } from "react"

//antd
import { Layout, Menu, Row, Space, Col, Avatar, Dropdown } from "antd"
// import {
//   HomeOutlined
// } from "@ant-design/icons";
import StorageService from "../services/storage"
import APIService from "../services/api"
//redux actions
import { Link, useHistory } from "react-router-dom"
import find from "lodash/find"
// import debounce from 'lodash/debounce';
// import filter from 'lodash/filter';

import { MENU } from "../constants/common"
import CommonService from "../services/common"
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react"

const { Header, Content, Sider } = Layout
// const { Title } = Typography;
// const loggedInUser = StorageService.getData("user");
// const { Option } = Select;

function AdminLayout({ children }) {
  const [selectedKeys, updateSelectedKeys] = useState(["1"])
  const { isAuthenticated, user, logout } = useAuth0()

  let history = useHistory()
  useEffect(() => {
    listenRouterChangeEvent()
  }, [])

  const listenRouterChangeEvent = () => {
    let activeMenu = find(MENU, { link: window.location.pathname })
    if (activeMenu) {
      updateSelectedKeys([activeMenu.id.toString()])
    }
  }

  const getUserData = async () => {
    await APIService.request("api/v1/auth/user", "post", { ...user }).then(
      ({ res }) => {
        if (res && res.code === "OK") {
          StorageService.setData("user", res.data.user);
        }
      }
    );
  };

  useEffect(() => {
    if (isAuthenticated && user) {
      let userData = StorageService.getData("user");
      if (!userData) {
        getUserData();
      }
    }
  }, [user, isAuthenticated, getUserData]);

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <div
          onClick={() => {
            StorageService.clearAllData()
            logout({ logoutParams: { returnTo: window.location.origin } })
          }}
        >
          Logout
        </div>
      </Menu.Item>
    </Menu>
  )

  if (!isAuthenticated) {
    history.push(`/login`)
    return null
  } else {
    return (
      <Layout className="admin-layout">
        <Sider
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
          }}
        >
          <div className="logo">
            <div>
              <img
                alt="img-logo"
                style={{ width: "100%" }}
                src={require("../assets/image/logo.png")}
              ></img>
            </div>
          </div>
          <Menu theme="dark" mode="inline" selectedKeys={selectedKeys}>
            {MENU.map((menu) => {
              return (
                <Menu.Item
                  key={menu.id}
                  icon={menu.icon}
                  onClick={() => {
                    updateSelectedKeys([menu.id.toString()])
                  }}
                >
                  <Link to={menu.link}>{menu.label}</Link>
                </Menu.Item>
              )
            })}
          </Menu>
        </Sider>
        <Layout style={{ padding: "0 24px 24px" }}>
          <Header>
            <Row type="flex" justify="space-between" align="middle">
              <Col></Col>
              <Col>
                <Row justify="end" align="middle">
                  <Space>
                    <Dropdown overlay={menu} trigger={["click"]}>
                      <div
                        className="ant-dropdown-link"
                        onClick={(e) => e.preventDefault()}
                      >
                        <Avatar
                          style={{
                            color: "#f56a00",
                            backgroundColor: "#fde3cf",
                          }}
                        >
                          {CommonService.initial(user?.nickname)}
                        </Avatar>
                      </div>
                    </Dropdown>
                  </Space>
                </Row>
              </Col>
            </Row>
          </Header>
          <Content
            style={{
              padding: 24,
              marginLeft: "200px",
              minHeight: 280,
            }}
            className="site-layout-background"
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    )
  }
}

// export default AdminLayout

export default withAuthenticationRequired(AdminLayout, {
  onRedirecting: () => (
    <div class="loader loading">
      <img src="/loader.svg" alt="loader" />
    </div>
  ),
})

import React, { useEffect, useState } from "react";
import {
    Input,
    Select,
    Modal,
    Row,
    Form,
    Col,
    Spin,
} from "antd";
import APIService from "../../services/api";
const { Option } = Select;
function AddEditAppointment({ modalData, setModalData }) {
    const [userform] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useState();
    useEffect(() => {
        setLoading(true);
        if (modalData.data) {
            setInitialValues({
                ...modalData.data,
                appointmentType: modalData.data.appointmentType ? modalData.data.appointmentType : "in person"
            });
        } else {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (initialValues) {
            setLoading(false);
        }
    }, [initialValues]);

    const handleCancel = () => {
        setModalData({ visible: false });
    };

    const onFinish = (values) => {
        setLoading(true);

        if (modalData?.data?._id) {
            APIService.requestWithToken(
                "api/v1/admin/appointment-update/" + modalData.data._id,
                "post",
                { appointmentType: values.appointmentType }
            ).then(({ res }) => {
                if (res && res.code === "OK") {
                    setModalData({ visible: false, success: true });
                    setLoading(false);
                }
            });
        }
    };



    return (
        <Modal
            width={800}
            title={modalData?.data?._id ? "Update Appointment" : "Add Appointment"}
            visible={modalData.visible}
            okText="Submit"
            cancelText="Cancel"
            onOk={() => {
                userform
                    .validateFields()
                    .then((values) => {
                        userform.resetFields();
                        onFinish(values);
                    })
                    .catch((info) => {
                        console.log("Validate Failed:", info);
                    });
            }}
            onCancel={handleCancel}
        >
            {!loading ? (
                <Form
                    form={userform}
                    initialValues={initialValues}
                    name="control-hooks"
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Row justify="space-between">
                        <Col xs={7} md={7}>
                            <Form.Item
                                name="firstName"
                                label="First Name"
                                rules={[{ required: true }]}
                            >
                                <Input placeholder="First Name" disabled />
                            </Form.Item>
                        </Col>
                        <Col xs={7} md={7}>
                            <Form.Item
                                name="lastName"
                                label="Last Name"
                                rules={[{ required: true, message: "Last Name is required." }]}
                            >
                                <Input placeholder="Last Name" disabled />
                            </Form.Item>
                        </Col>

                        <Col xs={7} md={9}>
                            <Form.Item
                                name="appointmentType"
                                label="Appointment Type"
                            >
                                <Input placeholder="appointmentType" />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            ) : (
                <Spin />
            )}
        </Modal>
    );
}
export default AddEditAppointment;

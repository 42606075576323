import React, { useRef, useState } from "react"
import { Input, Row, Form, Col, Button, notification, Tag, Checkbox } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import { TweenOneGroup } from "rc-tween-one"
import StorageService from "../../services/storage"
import APIService from "../../services/api"

function HospitalChatBotTab({ data, id }) {
  const [form] = Form.useForm()
  const inputRef = useRef(null)
  const [inputVisible, setInputVisible] = useState(false)
  const [inputValue, setInputValue] = useState("")
  const [tags, setTags] = useState(
    data?.hospitalChatbot?.chatbot_askAQuestionStaffEmails
      ? data?.hospitalChatbot?.chatbot_askAQuestionStaffEmails
      : []
  )
  const hideAskQuestion = data?.hospitalChatbot?.hideAskQuestion;
  const hideNotFeelingWell = data?.hospitalChatbot?.hideNotFeelingWell;
  const hideLookingForSomethingElse = data?.hospitalChatbot?.hideLookingForSomethingElse;

  const [hideTab,setHideTab] = useState({
    hideAskQuestion: hideAskQuestion ? data?.hospitalChatbot?.hideAskQuestion : false,
    hideNotFeelingWell: hideNotFeelingWell ? data?.hospitalChatbot?.hideNotFeelingWell : false,
    hideLookingForSomethingElse: hideLookingForSomethingElse ? data?.hospitalChatbot?.hideLookingForSomethingElse : false,
  })
  const openNotification = () => {
    notification.open({
      message: "Updated Successfully",
    })
  }
  const onSettingFinish = () => {
    form
      .validateFields()
      .then((values) => {
        let userData = StorageService.getData("user")
        let requestObj = {
          ...values,
          hospitalChatbot: {
            locationUrl: values.locationUrl,
            doctorUrl: values.doctorUrl,
            patientForm: values.patientForm,
            referralUrl: values.referralUrl,
            patientServices: values.patientServices,
            billPay: values.billPay,
            chatbot_askAQuestionStaffEmails: tags,
            hideAskQuestion: hideTab?.hideAskQuestion,
            hideNotFeelingWell: hideTab?.hideNotFeelingWell,
            hideLookingForSomethingElse: hideTab?.hideLookingForSomethingElse,
          },
          userId: userData?._id,
          organizationId: id,
          organizationName: data?.name,
          organizationType: data?.organizationType,
        }
        APIService.requestWithToken(
          "api/v1/organization/addOrEditOrganization",
          "post",
          requestObj
        )
      })
      .finally(() => {
        openNotification()
      })
  }

  const showInput = () => {
    setInputVisible(true)
  }

  const handleInputChange = (e) => {
    setInputValue(e.target.value)
  }

  const handleInputConfirm = (e) => {
    if (
      inputValue &&
      tags.indexOf(inputValue) === -1 &&
      form.getFieldError("Email")?.length === 0
    ) {
      setTags([...tags, inputValue])
    }

    setInputVisible(false)
    setInputValue("")
  }

  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag)
    setTags(newTags)
  }

  const forMap = (tag) => {
    const tagElem = (
      <Tag
        closable
        onClose={(e) => {
          e.preventDefault()
          handleClose(tag)
        }}
      >
        {tag}
      </Tag>
    )
    return (
      <span
        key={tag}
        style={{
          display: "inline-block",
        }}
      >
        {tagElem}
      </span>
    )
  }

  const onChange = (e) => {
    setHideTab({...hideTab,[e?.target?.name]: e?.target?.checked})
  }

  const tagChild = tags.map(forMap)
  return (
    <>
      <Form
        form={form}
        layout="vertical"
        name="control-hooks"
        initialValues={data.hospitalChatbot}
        onFinish={onSettingFinish}
      >
        <Row justify="space-between">
          <Col xs={7} md={11}>
            <Form.Item name="locationUrl" label="Find a Location URL">
              <Input placeholder="Find a Location URL" />
            </Form.Item>
          </Col>

          <Col xs={7} md={11}>
            <Form.Item name="doctorUrl" label="Find a Doctor URL">
              <Input placeholder="Find a Doctor URL" />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col xs={7} md={11}>
            <Form.Item name="patientForm" label="Patient Forms">
              <Input placeholder="Patient Forms" />
            </Form.Item>
          </Col>

          <Col xs={7} md={11}>
            <Form.Item name="referralUrl" label="Submit a Referral URL">
              <Input placeholder="Submit a Referral URL" />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col xs={7} md={11}>
            <Form.Item name="patientServices" label="Patient Services">
              <Input placeholder="Patient Services" />
            </Form.Item>
          </Col>
          <Col xs={7} md={11}>
            <Form.Item
              name="chatbot_askAQuestionStaffEmails"
              label="Ask a question Staff emails"
            // onChange={(e) => setDepartments(e.target.value)}
            >
              <>
                {!inputVisible && (
                  <Tag onClick={showInput} className="site-tag-plus">
                    <PlusOutlined /> New Staff emails
                  </Tag>
                )}
                {inputVisible && (
                  <Input
                    // name="Email"
                    ref={inputRef}
                    type="email"
                    size="medium"
                    placeholder="Staff emails"
                    maxLength={50}
                    rules={[{ required: true, message: "Only Number allowed" }]}
                    style={{
                      width: "100%",
                    }}
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleInputConfirm}
                    onPressEnter={handleInputConfirm}
                  />
                )}
                <div style={{ marginTop: 16 }}>
                  <TweenOneGroup
                    enter={{
                      scale: 0.8,
                      opacity: 0,
                      type: "from",
                      duration: 100,
                    }}
                    onEnd={(e) => {
                      if (e.type === "appear" || e.type === "enter") {
                        e.target.style = "display: inline-block"
                      }
                    }}
                    leave={{
                      opacity: 0,
                      width: 0,
                      scale: 0,
                      duration: 200,
                    }}
                    appear={false}
                  >
                    {tagChild}
                  </TweenOneGroup>
                </div>
              </>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col xs={7} md={11}>
            <Form.Item name="billPay" label="Bill Pay">
              <Input placeholder="Bill Pay" />
            </Form.Item>
          </Col>
        </Row>
        <Row xs={7} style={{gap:"20px"}}>
            <Form.Item name="name" label="" >
              <Checkbox name={"hideAskQuestion"} checked={hideTab?.hideAskQuestion} onChange={onChange}>
                Ask a question
              </Checkbox>
            </Form.Item>
            <Form.Item name="name" label="" >
              <Checkbox name={"hideNotFeelingWell"} checked={hideTab?.hideNotFeelingWell} onChange={onChange}>
                Not feeling well?
              </Checkbox>
            </Form.Item>
            <Form.Item name="name" label="" >
              <Checkbox name={"hideLookingForSomethingElse"} checked={hideTab?.hideLookingForSomethingElse} onChange={onChange}>
                Looking for something else
              </Checkbox>
            </Form.Item>
        </Row>
        <Form.Item
          wrapperCol={{
            offset: 11,
            span: 16,
          }}
        >
          <div>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  )
}

export default HospitalChatBotTab

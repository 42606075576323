import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { Button, Steps } from 'antd';
import { StepBackwardOutlined } from '@ant-design/icons';

import './index.css'
import General from './General';
import EpicStep from './EpicStep';
import HospitalChatBotStep from './HospitalChatBotStep';
import NewConsultTimeSlotsDifferenceStep from './NewConsultTimeSlotsDifferenceStep';

import APIService from '../../../services/api';

const { Step } = Steps;

function AddSteps() {
  const history = useHistory()
  const [current, setCurrent] = useState(0);
  const [stepData, setStepData] = useState({})
  const [step2Data, setStep2Data] = useState({})
  const [step3Data, setStep3Data] = useState({})
  const [step4Data, setStep4Data] = useState({})
  const [tags, setTags] = useState([]);
  const [departmentsData, setDepartmentsData] = useState([])

  useEffect(() => {
    APIService.requestWithToken(`api/v1/admin/department/fetch-departments`, 'get')
      .then(res => {
        setDepartmentsData(res.res.data);
      })
  }, []);

  const next = (nextStep = 1) => {
    setCurrent(current + nextStep);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const skip = () => {
    setCurrent(3);
  }

  const steps = [
    {
      title: 'General',
      content: <General setStepData={setStepData} next={next} stepData={stepData} departmentsData={departmentsData} setTags={setTags} tags={tags} />,
    },
    {
      title: 'Epic',
      content: <EpicStep setStep2Data={setStep2Data} next={next} prev={prev} skip={skip} step2Data={step2Data} />,
    },
    {
      title: 'Hospital Chat Bot',
      content: <HospitalChatBotStep setStep3Data={setStep3Data} next={next} prev={prev} skip={skip} step3Data={step3Data} />,
    },
    {
      title: 'New-Consult-Time-Slots-Difference',
      content: <NewConsultTimeSlotsDifferenceStep next={next} prev={prev}
        stepData={stepData}
        step2Data={step2Data}
        step3Data={step3Data}
        setStep4Data={setStep4Data}
        step4Data={step4Data}
        tags={tags}
      />,
    },
  ];

  return (
    <>
      <div style={{ float: 'right' }}>
        <Button type="primary" onClick={() => history.push(`/app/organizations`)}>
          <StepBackwardOutlined /> Back
        </Button>
      </div>
      <Steps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[current].content}</div>
    </>
  )
}

export default AddSteps

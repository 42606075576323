import React, { useEffect, useState } from "react";
import {
    Table,
    Input,
    Space,
    Button,
    Typography,
    Select,
    Spin,
    DatePicker,
    Modal
} from "antd";
import { ExclamationCircleOutlined, DeleteOutlined, EditTwoTone } from "@ant-design/icons";
import APIService from "../../services/api";
import AddEditAppointment from "./AddEditAppointment";
import moment from 'moment';
const { confirm } = Modal;
const { RangePicker } = DatePicker;

const dateFormat = 'MM/DD/YYYY';

const { Title } = Typography;
const { Search } = Input;
function AppointmentList() {
    const scrollHeight = window.innerHeight - 337;
    const [modalData, setModalData] = useState({
        visible: false,
        data: {},
        success: false,
    });
    const [filterOrganization, updateOrganization] = useState();
    const [organizationList, setOrganizationList] = useState([]);
    const [fetching, setFetching] = React.useState(false);
    const [filterDate, updateDates] = React.useState({
        dateFrom: moment().startOf("month").format("MM/DD/YYYY"),
        dateTo: moment().endOf("MM/DD/YYYY")
    });
    const [tableData, updateTableData] = useState({
        loading: false,
        dataSource: [],
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0,
        },
    });
    const [searchQuery, updateSearchQuery] = useState(undefined);
    const columns = [
        {
            title: "Name",
            render: (record) => {
                return record.firstName + ' ' + record.lastName
            },
        },
        {
            title: "DOB",
            dataIndex: "birthdate",

        },
        {
            title: "Appointment Time.",
            width: 130,
            dataIndex: "appointmentTime",
            render: (text) => {
                return moment(text).format("MM-DD-YYYY hh:mm a");
            }
        },
        {
            title: "Reason",
            dataIndex: "appointmentReason",

        },
        {
            title: "doctor",
            dataIndex: "doctor",
            render: (record) => {
                console.log(record);
                if (record) {
                    return record.title + ' ' + record.firstName + ' ' + record.lastName;
                } else {
                    return '';
                }

            },
        },
        {
            title: "Type",
            dataIndex: "appointmentType",
            render: (text) => {
                return text && text === "video" ? text : 'In person';
            },
        },
        {
            title: "Status",
            dataIndex: "appointmentStatus",

        },
        {
            title: "Actions",
            width: 100,
            align: 'center',
            render: (obj) => {
                return (
                    <Space>
                        <EditTwoTone
                            onClick={() =>
                                setModalData({
                                    ...modalData,
                                    visible: true,
                                    data: obj,
                                })
                            }
                        />
                        <DeleteOutlined
                            onClick={() =>
                                confirm({
                                    title: 'Do you Want to delete these items?',
                                    icon: <ExclamationCircleOutlined />,
                                    content: '',
                                    onOk() {
                                        setFetching(true);
                                        APIService.requestWithToken(
                                            "api/v1/admin/appointment-delete/" + obj._id,
                                            "get"
                                        ).then(({ res }) => {
                                            if (res && res.code === "OK") {
                                                getData(tableData.pagination);
                                                setFetching(false);
                                            }
                                        });
                                    },
                                    onCancel() {
                                        console.log('Cancel');
                                    },
                                })
                            }
                        />
                    </Space>
                );
            },
        },
    ];
    useEffect(() => {
        getOrganizationsData();
        getData(tableData.pagination);
    }, []);

    useEffect(() => {
        if (modalData.success) {
            getData(tableData.pagination);
            setModalData({
                visible: false,
                data: {},
                success: false
            })
        }
    }, [modalData.success]);

    const debounceFetcher = (searchStr) => {
        setFetching(true);
        let searchQuery = "";
        if (searchStr) {
            searchQuery = { name: { $regex: searchStr, $options: "i" } };
        }
        if (searchStr.length >= 3) {
            getOrganizationsData(searchQuery);
        }

    };

    const getOrganizationsData = (searchQuery) => {
        APIService.requestWithToken("api/v1/admin/org/list", "get", {
            query: searchQuery ? JSON.stringify(searchQuery) : null,
            select: JSON.stringify(["_id", "name"]),
            sort: JSON.stringify({ name: 1, createdAt: -1 }),
        }).then(({ res }) => {
            if (res && res.code === "OK" && res.data.count) {
                setFetching(false);
                let orgList = res.data.list.map((a) => {
                    return {
                        label: a.name,
                        value: a._id,
                    };
                });
                setOrganizationList(orgList);
                updateOrganization(orgList[0].value)
            }
        });
    };

    const getData = (pagination) => {
        updateTableData({
            ...tableData,
            pagination: {
                ...pagination,
            },
            loading: true,
            dataSource: [],
        });
        let query = {};
        if (searchQuery) {
            query = { ...searchQuery };
        }
        if (filterOrganization) {
            query = { ...query, organizationId: filterOrganization };
        }
        if (filterDate.dateFrom && filterDate.dateTo) {
            query = { ...query, ...filterDate };
        }

        APIService.requestWithToken("api/v1/admin/appointment/web-list", "get", {
            pageNo: parseInt(pagination.current),
            limit: pagination.pageSize,
            query: query ? JSON.stringify(query) : null,
            sort: JSON.stringify({ createdAt: -1 }),
        }, { "organization": filterOrganization }).then(({ res }) => {
            if (res && res.code === "OK") {
                updateTableData({
                    loading: false,
                    dataSource: res.data.list,
                    pagination: {
                        ...pagination,
                        total: res.data.count,
                    },
                });
            }
        });
    };

    const updateActive = (value, userId) => {
        let reqObj = { deactivated: value, active: !value }

        APIService.requestWithToken("api/v1/admin/partial-update/" + userId, "post", reqObj).then(({ res }) => {
            if (res && res.code === "OK") {
                getData(tableData.pagination);
            }
        });

    }
    const updateDateFilter = (dates, strings) => {

        updateDates({
            dateFrom: strings[0],
            dateTo: strings[1]
        })
    }

    return (
        <div>
            <div className="df jsb am">
                <Title level={3}> Appointments ({tableData.pagination.total})</Title>
                <Space>
                    <Space>
                        <Search
                            placeholder="Input search text"
                            onSearch={(searchStr) => {
                                updateSearchQuery({
                                    name: { $regex: searchStr, $options: "i" },
                                });
                            }}
                            style={{ width: 200 }}
                        />
                        <RangePicker
                            defaultValue={[moment(filterDate.dateFrom, dateFormat), moment(filterDate.dateTo, dateFormat)]}
                            format={dateFormat}
                            onChange={updateDateFilter}
                        />
                        <Select
                            showSearch
                            allowClear
                            style={{ width: 200 }}
                            placeholder="Select an Organization"
                            optionFilterProp="children"
                            onChange={(newVal) => updateOrganization(newVal)}
                            onSearch={(value) => debounceFetcher(value)}
                            filterOption={false}
                            notFoundContent={fetching ? <Spin size="small" /> : null}
                            value={filterOrganization}
                            options={organizationList}
                        ></Select>

                    </Space>
                    <Button
                        type="primary"
                        onClick={() => getData(tableData.pagination)}
                    >
                        {" "}
                        Apply
                    </Button>
                </Space>
            </div>

            <Table
                {...tableData}
                columns={columns}
                onChange={getData}
                scroll={{ y: scrollHeight }}
            ></Table>


            {modalData.visible ? (
                <AddEditAppointment
                    modalData={modalData}
                    setModalData={setModalData}
                ></AddEditAppointment>
            ) : null}
        </div>
    );

}

export default AppointmentList;

import React, { useRef, useState } from 'react'
import {
  Input,
  Button,
  Row,
  Form,
  Col,
  // Radio,
  notification,
  Select,
  // Space,
  Table,
  InputNumber,
  Popconfirm,
  Space,
  Switch,
  Tag,
} from 'antd'
// import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"
import StorageService from '../../services/storage'
import APIService from '../../services/api'
import { PlusOutlined } from '@ant-design/icons'
import { Tabs } from 'antd'

const IntegrationOutboundTab = ({ data: propsData, id }) => {
  const outboundMessages = propsData?.integration?.outboundMessages
  let outbound = []
  // eslint-disable-next-line no-unused-expressions
  outboundMessages?.map((data, key) => {
    const obj = {
      key: key.toString(),
      enabled: data?.enabled,
      port: +data?.port,
      path: data?.path,
      type: data?.type,
      disable: data?.disable,
    }

    return outbound.push(obj)
  })
  const { TabPane } = Tabs
  const [form] = Form.useForm()
  const [selectedValue, setSelectedValue] = useState('select path')
  const [inputValue, setInputValue] = useState('')
  const [inputVisible, setInputVisible] = useState(false)
  const [table, setTable] = useState(false)
  const [tags, setTags] = useState(
    propsData?.integration?.sourceVisitTypes || []
  )
  const inputRef = useRef(null)
  // const [outBoundTypeValue, setOutBoundTypeValue] = useState(
  //   "select outbound type"
  // )
  // const [outOutboundPathValue, setOutOutboundPathValue] = useState(
  //   "select outbound path"
  // )
  const [data, setData] = useState(outbound)
  const [editingKey, setEditingKey] = useState('')
  const [count, setCount] = useState(2)
  const [addDisable, setAddDisable] = useState()
  const [source, setSource] = useState()
  const columns = [
    {
      title: 'Outbound Enabled',
      dataIndex: 'enabled',
      // width: "25%",
      editable: true,
      render: (text, record) => text.toString(),
    },
    {
      title: 'Outbound Ports',
      dataIndex: 'port',
      // width: "15%",
      editable: true,
    },
    {
      title: 'Outbound Type',
      dataIndex: 'type',
      // width: "40%",
      editable: true,
    },
    {
      title: 'Outbound Path',
      dataIndex: 'path',
      // width: "40%",
      editable: true,
    },
    {
      title: 'Actions',
      dataIndex: 'Actions',
      render: (_, record, key) => {
        const editable = isEditing(record)
        return (
          <>
            <Space size='middle'>
              {editable ? (
                <span style={{ display: 'flex' }}>
                  <Popconfirm
                    title='Sure to save?'
                    onClick={() => save(record.key)}
                  >
                    <Button
                      type='primary'
                      style={{
                        marginRight: 8,
                        cursor: 'pointer',
                      }}
                    >
                      Save
                    </Button>
                  </Popconfirm>
                  <Popconfirm
                    title='Sure to cancel?'
                    onConfirm={() => cancel(record)}
                  >
                    <Button style={{ cursor: 'pointer' }}>Cancel</Button>
                  </Popconfirm>
                </span>
              ) : (
                <Button
                  disabled={editingKey !== ''}
                  onClick={() => edit(record)}
                >
                  Edit
                </Button>
              )}
              {!addDisable && (
                <Space>
                  {/* {data.length >= 1 ? (
                  <Popconfirm
                    title="Sure to delete?"
                    onConfirm={() => handleDelete(record.key)}
                  >
                    <a>Delete</a>
                  </Popconfirm>
                ) : null} */}
                  <Button
                    type='primary'
                    // disabled={record.disable}
                    onClick={() => disable(record)}
                  >
                    {record.disable ? 'Enable' : 'Disable'}
                  </Button>
                </Space>
              )}
            </Space>
          </>
        )
      },
    },
  ]

  const handleChange = (value) => {
    setSelectedValue(value)
  }

  const handleInputChange = (e) => {
    setInputValue(e.target.value)
  }

  const showInput = () => {
    setInputVisible(true)
  }

  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag)
    setTags(newTags)
  }

  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue])
    }

    setInputVisible(false)
    setInputValue('')
  }

  const forMap = (tag) => {
    const tagElem = (
      <Tag
        closable
        onClose={(e) => {
          e.preventDefault()
          handleClose(tag)
        }}
      >
        {tag}
      </Tag>
    )
    return (
      <span
        key={tag}
        style={{
          display: 'inline-block',
        }}
      >
        {tagElem}
      </span>
    )
  }

  const tagChild = tags.map(forMap)

  // const handleOutBoundTypeChange = (value) => {
  //   setOutBoundTypeValue(value)
  // }

  // const handleOutboundPathChange = (value) => {
  //   setOutOutboundPathValue(value)
  // }

  const openNotification = () => {
    notification.open({
      message: 'Updated Successfully',
    })
  }

  const onSettingFinish = () => {
    form.validateFields().then((values) => {
      // let outbound = []
      // values.outboundMessages.map((data) => {
      //   const obj = {
      //     enabled: data?.enabled,
      //     port: +data?.port,
      //     path: data?.path,
      //     outboundType: data?.outboundType,
      //   }

      //   return outbound.push(obj)
      // })

      const value = {
        ...values,
        outboundMessages: data,
        sourceVisitTypes: tags,
      }

      let userData = StorageService.getData('user')
      let requestObj = {
        integration: value,
        userId: userData?._id,
        organizationId: id,
        organizationName: propsData?.name,
        organizationType: propsData?.organizationType,
      }
      APIService.requestWithToken(
        'api/v1/organization/addOrEditOrganization',
        'post',
        requestObj
      ).then(() => openNotification())
    })
  }

  const isEditing = (record) => record.key === editingKey

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      dataIndex === 'enabled' ? (
        <Switch size='medium' defaultChecked={record.enabled} />
      ) : dataIndex === 'port' ? (
        <InputNumber />
      ) : dataIndex === 'type' ? (
        <Input style={{ width: '100%' }} />
      ) : dataIndex === 'path' ? (
        <Input style={{ width: '100%' }} />
      ) : (
        <Input />
      )

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    )
  }

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    }
  })

  const edit = (record) => {
    form.setFieldsValue({
      name: '',
      age: '',
      address: '',
      disable: record.disable,
      ...record,
    })
    setEditingKey(record.key)
  }

  const disable = (record) => {
    const source = data.find((source) => source.key === record.key)
    if (source) {
      if (source.disable === true) {
        setSource(source.disable)
        source.disable = false
      } else {
        setSource(source.disable)
        source.disable = true
      }
    }
    setData(data)
  }

  const cancel = (record) => {
    setEditingKey('')
    setAddDisable(false)
    if (addDisable) {
      const newData = data.filter((item) => item.key !== record.key)
      setData(newData)
    }
  }

  const save = async (key) => {
    try {
      const row = await form.validateFields()
      const newData = [...data]
      const index = newData.findIndex((item) => key === item.key)
      if (index > -1) {
        const item = { ...newData[index], key: newData?.length - 1 }
        newData.splice(index, 1, {
          ...item,
          ...row,
        })
        setData(newData)
        setEditingKey('')
      } else {
        newData.push(row)
        setData(newData)
        setEditingKey('')
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo)
    }
  }

  const handleAdd = () => {
    const newData = {
      enabled: '',
      port: '',
      path: '',
      type: '',
      disable: false,
    }
    setData([...data, newData])
    setCount(count + 1)
    edit(newData)
    setAddDisable(true)
  }

  // const handleDelete = (key) => {
  //   const newData = data.filter((item) => item.key !== key)
  //   setData(newData)
  // }

  return (
    <>
      <Form
        form={form}
        layout='vertical'
        name='control-hooks'
        initialValues={{
          ...propsData.integration,
          // outboundPorts: data?.integration?.port.toString(),
        }}
        onFinish={onSettingFinish}
      >
        <Row justify='space-between'>
          <Col xs={7} md={11}>
            <Tabs
              defaultActiveKey='1'
              onChange={(e) => {
                setTable(e === '2')
              }}
            >
              <TabPane onClick={showInput} tab='General' key='1'>
                <Form.Item label='Source visit types'>
                  {!inputVisible && (
                    <Tag onClick={showInput} className='site-tag-plus'>
                      <PlusOutlined /> Source visit types
                    </Tag>
                  )}
                  {inputVisible && (
                    <Input
                      ref={inputRef}
                      label='Source visit types'
                      type='text'
                      size='medium'
                      placeholder='source visit types'
                      style={{
                        width: '100%',
                      }}
                      value={inputValue}
                      onChange={handleInputChange}
                      onPressEnter={handleInputConfirm}
                    />
                  )}

                  <div style={{ marginTop: 16 }}>{tagChild}</div>
                </Form.Item>
              </TabPane>

              <TabPane tab='Outbound' key='2'>
                <Form.Item name='outboundPath' label='Outbound Path'>
                  <Select
                    defaultValue={selectedValue}
                    style={{ width: '100%' }}
                    onChange={handleChange}
                    // value={selectedValue}
                    options={[
                      {
                        value: 'epic',
                        label: 'Epic',
                      },
                      {
                        value: 'ecw',
                        label: 'EcW',
                      },
                      {
                        value: "aprima",
                        label: "APrima"
                      },
                      {
                        value: "athena",
                        label: "Athena"
                      },
                      {
                        value: "nextgen",
                        label: "NextGen"
                      },
                      {
                        value: "drchrono",
                        label: "DrChrono"
                      }
                    ]}
                  />
                </Form.Item>
              </TabPane>
            </Tabs>
          </Col>
        </Row>

        {/* <Form.List name="outboundMessages">
          {(fields, { add, remove }) => (
            <>
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add Out Bound Messages field
                </Button>
              </Form.Item>
              {fields.map(({ key, name, ...restField }) => (
                <>
                  <Space
                    key={key}
                    style={{
                      display: "block",
                      marginBottom: 8,
                      alignItems: "center",
                    }}
                    align="baseline"
                  >
                    <Row justify="space-between">
                      <Col xs={7} md={11}>
                        <Form.Item
                          {...restField}
                          name={[name, "enabled"]}
                          // name="outboundEnabled"
                          label="Outbound Enabled"
                        >
                          <Radio.Group onChange={onChange} value={value}>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col xs={7} md={11}>
                        <Form.Item
                          {...restField}
                          name={[name, "port"]}
                          // name="outboundPorts"
                          label="Outbound Ports"
                          // rules={[
                          //   {
                          //     min: 4,
                          //     max: 4,
                          //     message: "minimum 4 number ports required",
                          //   },
                          // ]}
                        >
                          <Input
                            type="number"
                            placeholder="Outbound Ports"
                            maxLength={4}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <span style={{ marginLeft: "1rem" }} />
                      </Col>

                      <Col xs={7} md={11}>
                        <Form.Item
                          {...restField}
                          name={[name, "outboundType"]}
                          // name="outboundType"
                          label="Outbound Type"
                          rules={[
                            {
                              min: 3,
                              max: 3,
                              message: "minimum 3 character required",
                            },
                          ]}
                        >
                          <Select
                            defaultValue={outBoundTypeValue}
                            style={{ width: "100%" }}
                            onChange={handleOutBoundTypeChange}
                            // value={selectedValue}
                            options={[
                              {
                                value: "a04",
                                label: "a04",
                              },
                              {
                                value: "a05",
                                label: "a05",
                              },
                              {
                                value: "a08",
                                label: "a08",
                              },
                              {
                                value: "s12",
                                label: "s12",
                              },
                              {
                                value: "s15",
                                label: "s15",
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={7} md={11}>
                        <Form.Item
                          {...restField}
                          name={[name, "path"]}
                          // name="outboundPath"
                          label="Outbound Path"
                          rules={[
                            {
                              min: 3,
                              max: 3,
                              message: "minimum 3 character required",
                            },
                          ]}
                        >
                          <Select
                            defaultValue={outOutboundPathValue}
                            style={{ width: "100%" }}
                            onChange={handleOutboundPathChange}
                            // value={selectedValue}
                            options={[
                              {
                                value: "a04",
                                label: "a04",
                              },
                              {
                                value: "a05",
                                label: "a05",
                              },
                              {
                                value: "a08",
                                label: "a08",
                              },
                              {
                                value: "s12",
                                label: "s12",
                              },
                              {
                                value: "s15",
                                label: "s15",
                              },
                            ]}
                          />
                          <Input
                            type="text"
                            placeholder="Outbound Path"
                            maxLength={5}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <MinusCircleOutlined
                          onClick={() => remove(name)}
                          // style={{ marginLeft: "1rem" }}
                        />
                      </Col>
                    </Row>
                  </Space>
                </>
              ))}
            </>
          )}
        </Form.List> */}
        <div>
          <Button
            onClick={handleAdd}
            type='primary'
            style={{
              marginBottom: 16,
              float: 'right',
            }}
          >
            Add
          </Button>

          {table && (
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              bordered
              dataSource={data}
              columns={mergedColumns}
              rowClassName='editable-row'
              pagination={{
                onChange: cancel,
              }}
            />
          )}
        </div>

        <Form.Item
          wrapperCol={{
            offset: 11,
            span: 16,
          }}
        >
          <div>
            <Button type='primary' htmlType='submit'>
              Save
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  )
}

export default IntegrationOutboundTab
